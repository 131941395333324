import GuestLayout from './layouts/GuestLayout';
import AuthorizedLayout from './layouts/AuthorizedLayout';
import BlankLayout from './layouts/BlankLayout';
import React from 'react';
import { Switch } from 'react-router-dom';
import AppRoute from "./shared/AppRoute";
import LoginPage from './pages/LoginPage.js';
import HomePage from './pages/HomePage';
import AdminPage from './pages/AdminPage';
import Sayfa from './pages/ArayuzSayfalar/Sayfa';
import Subeler from './pages/ArayuzSayfalar/Subeler';
import Iletisim from './pages/ArayuzSayfalar/Iletisim';
import InsanKaynaklari from './pages/ArayuzSayfalar/InsanKaynaklari';
import Haberler from './pages/ArayuzSayfalar/Haberler';
import HaberDetay from './pages/ArayuzSayfalar/HaberDetay';
import BayiBasvurusu from './pages/ArayuzSayfalar/BayiBasvurusu';
import KuryeCagir from './pages/ArayuzSayfalar/KuryeCagir';
import KargoTakip from './pages/ArayuzSayfalar/KargoTakip';
import RolPage from './pages/YonetimselAraclar/RolPage';
import SiteAyarlariPage from './pages/YonetimselAraclar/SiteAyarlariPage';
import SiteSayfalariPage from './pages/YonetimselAraclar/SiteSayfalariPage';
import SiteSlaytlariPage from './pages/YonetimselAraclar/SiteSlaytlariPage';
import HaberlerPage from './pages/YonetimselAraclar/HaberlerPage';
import SubelerPage from './pages/YonetimselAraclar/SubelerPage';
import DortluSectionPage from './pages/YonetimselAraclar/DortluSectionPage';
import TekliSectionPage from './pages/YonetimselAraclar/TekliSectionPage';
import BannerSectionPage from './pages/YonetimselAraclar/BannerSectionPage';
import EbultenAboneleriPage from './pages/YonetimselAraclar/EbultenAboneleriPage';
import UlkeIlIlceAktarmaPage from './pages/YonetimselAraclar/UlkeIlIlceAktarmaPage';
import SectionPage from './pages/YonetimselAraclar/SectionPage';
import InsanKaynaklariPage from './pages/YonetimselAraclar/InsanKaynaklariPage';
import BayiBasvurulariPage from './pages/YonetimselAraclar/BayiBasvurulariPage';
import KuryePage from './pages/YonetimselAraclar/KuryePage';
import PopupAyarPage from './pages/YonetimselAraclar/PopupAyarlariPage';


export const routes = <Switch>

    <AppRoute layout={GuestLayout} exact path="/login" component={LoginPage} />
    <AppRoute layout={AuthorizedLayout} exact path="/yonetimselaraclar" component={AdminPage} />
    <AppRoute layout={AuthorizedLayout} exact path="/yonetimselaraclar/roller" component={RolPage} />
    <AppRoute layout={AuthorizedLayout} exact path="/yonetimselaraclar/siteayarlari" component={SiteAyarlariPage} />
    <AppRoute layout={AuthorizedLayout} exact path="/yonetimselaraclar/sitesayfalari" component={SiteSayfalariPage} />
    <AppRoute layout={AuthorizedLayout} exact path="/yonetimselaraclar/siteslaytlar" component={SiteSlaytlariPage} />
    <AppRoute layout={AuthorizedLayout} exact path="/yonetimselaraclar/haberler" component={HaberlerPage} />
    <AppRoute layout={AuthorizedLayout} exact path="/yonetimselaraclar/subeler" component={SubelerPage} />
    <AppRoute layout={AuthorizedLayout} exact path="/yonetimselaraclar/dortlubolmeler" component={DortluSectionPage} />
    <AppRoute layout={AuthorizedLayout} exact path="/yonetimselaraclar/teklibolmeler" component={TekliSectionPage} />
    <AppRoute layout={AuthorizedLayout} exact path="/yonetimselaraclar/bannerlar" component={BannerSectionPage} />
    <AppRoute layout={AuthorizedLayout} exact path="/yonetimselaraclar/ebultenaboneleri" component={EbultenAboneleriPage} />
    <AppRoute layout={AuthorizedLayout} exact path="/yonetimselaraclar/UlkeIlIlceAktarma" component={UlkeIlIlceAktarmaPage} />
    <AppRoute layout={AuthorizedLayout} exact path="/yonetimselaraclar/bolmeler" component={SectionPage} />
    <AppRoute layout={AuthorizedLayout} exact path="/yonetimselaraclar/insankaynaklari" component={InsanKaynaklariPage} />
    <AppRoute layout={AuthorizedLayout} exact path="/yonetimselaraclar/bayibasvurulari" component={BayiBasvurulariPage} />
    <AppRoute layout={AuthorizedLayout} exact path="/yonetimselaraclar/kuryecagrilari" component={KuryePage} />
    <AppRoute layout={AuthorizedLayout} exact path="/yonetimselaraclar/acilisayarlari" component={PopupAyarPage} />
    <AppRoute layout={BlankLayout} exact path="/anasayfa" component={HomePage} />
    <AppRoute layout={BlankLayout} exact path="/subeler" component={Subeler} />
    <AppRoute layout={BlankLayout} exact path="/haberler" component={Haberler} />
    <AppRoute layout={BlankLayout} exact path="/iletisim" component={Iletisim} />
    <AppRoute layout={BlankLayout} exact path="/kargotakip/:kargotakipno" component={KargoTakip} />
    <AppRoute layout={BlankLayout} exact path="/insankaynaklari" component={InsanKaynaklari} />
    <AppRoute layout={BlankLayout} exact path="/bayilik" component={BayiBasvurusu} />
    <AppRoute layout={BlankLayout} exact path="/kuryecagir" component={KuryeCagir} />
    <AppRoute layout={BlankLayout} exact path="/:sayfakodu/:seoanahtarkelimesi" component={Sayfa} />
    <AppRoute layout={BlankLayout} exact path="/haberler/haberdetay/:id" component={HaberDetay} />
    <AppRoute layout={BlankLayout} exact path="/" component={HomePage} />
</Switch>;