import React from 'react';
import { siteApi } from '../../Utils';
import Globals from '../../Globals';
import service from 'axios';
import notify from 'devextreme/ui/notify';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import Form, { SimpleItem, GroupItem, Label } from 'devextreme-react/form';
import { withRouter } from "react-router";
import { alert } from 'devextreme/ui/dialog';
class SiteAyarlariPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingText: "Ayar bilgisi getiriliyor"
        }
        this.formData = {
            Id: 0,
            SiteBaslik: "",
            EmailAdresi: "",
            Telefon: "",
            WhatsappTel: "",
            FacebookAdresi: "",
            TwitterAdresi: "",
            InstagramAdresi: "",
            LinkedinAdresi: "",
            DribbleAdresi: "",
            YoutubeAdresi: "",
            Koordinat: "",
            FooterAdres: "",
            FooterCopyright: "",
            FooterArkaPlan: "",
            SayfaIciLink: false,
            RowVersion: 0,
            Logo: "",
            BasvuruBaslik: "",
            BasvuruAciklama: "",
            BasvuruResim: "",
            IkBaslik: "",
            IkAciklama: "",
            IkResim: "",
            IletisimBaslik: "",
            IletisimAciklama: "",
            HaberlerBaslik: "",
            HaberlerAciklama: "",
            SubelerBaslik: "",
            SubelerAciklama: "",
            PopupGoster: false
        };
        this.getSiteAyar = this.getSiteAyar.bind(this);
        this.onUploaded = this.onUploaded.bind(this);
        this.onUploadedFooterArkaPlan = this.onUploadedFooterArkaPlan.bind(this);
        this.onUploadedBasvuruTitle = this.onUploadedBasvuruTitle.bind(this);
        this.onUploadedIkTitle = this.onUploadedIkTitle.bind(this);
        this.headers = { 'Authorization': 'Bearer ' + Globals.Token };
        this.getSiteAyar()
            .then(res => {
                console.log(res);
            })
            .catch(error => {
                alert(error);
            }).finally(() => {
                this.setLoadingText();
            })

    }

    //#region  form fonksiyonları

    onUploaded(e) {
        if (e.value.length > 0) {
            this.formData.Logo = e.value[0].name;
            // this.setState({
            //     Logo: e.value[0].name
            // });
            notify("Logo yüklendi", "success", 5000);
        }
    }

    onUploadedFooterArkaPlan(e) {
        if (e.value.length > 0) {
            this.formData.FooterArkaPlan = e.value[0].name;
            notify("Arkaplan resmi yüklendi", "success", 5000);
        }
    }

    onUploadedBasvuruTitle(e) {
        if (e.value.length > 0) {
            this.formData.BasvuruResim = e.value[0].name;
            notify("Başvuru sayfası resmi yüklendi", "success", 5000);
        }
    }

    onUploadedIkTitle(e) {
        if (e.value.length > 0) {
            this.formData.IkResim = e.value[0].name;
            notify("İnsan kaynakları sayfası resmi yüklendi", "success", 5000);
        }
    }

    //#endregion

    //#region servis fonksiyonları

    async getSiteAyar() {
        return new Promise((resolve, reject) => {
            this.setState({ loadingText: "Ayar bilgisi getiriliyor..." });
            service.get(siteApi + 'websiteayar/getSiteAyar', {
                headers: {
                    'Authorization': 'Bearer ' + Globals.Token
                }
            }).then(res => {
                if (res.data.Success) {
                    this.formData = {
                        Id: res.data.Data.Id,
                        SiteBaslik: res.data.Data.SiteBaslik,
                        EmailAdresi: res.data.Data.EmailAdresi,
                        Telefon: res.data.Data.Telefon,
                        FacebookAdresi: res.data.Data.FacebookAdresi,
                        TwitterAdresi: res.data.Data.TwitterAdresi,
                        InstagramAdresi: res.data.Data.InstagramAdresi,
                        LinkedinAdresi: res.data.Data.LinkedinAdresi,
                        DribbleAdresi: res.data.Data.DribbleAdresi,
                        Koordinat: res.data.Data.Koordinat,
                        YoutubeAdresi: res.data.Data.YoutubeAdresi,
                        FooterAdres: res.data.Data.FooterAdres,
                        FooterCopyright: res.data.Data.FooterCopyright,
                        SayfaIciLink: res.data.Data.SayfaIciLink,
                        RowVersion: res.data.Data.RowVersion,
                        Logo: res.data.Data.Logo,
                        WhatsappTel: res.data.Data.WhatsappTel,
                        FooterArkaPlan: res.data.Data.FooterArkaPlan,
                        BasvuruBaslik: res.data.Data.BasvuruBaslik,
                        BasvuruAciklama: res.data.Data.BasvuruAciklama,
                        BasvuruResim: res.data.Data.BasvuruResim,
                        KuryeBaslik: res.data.Data.KuryeBaslik,
                        KuryeAciklama: res.data.Data.KuryeAciklama,
                        KuryeResim: res.data.Data.KuryeResim,
                        IkBaslik: res.data.Data.IkBaslik,
                        IkAciklama: res.data.Data.IkAciklama,
                        IkResim: res.data.Data.IkResim,
                        IletisimBaslik: res.data.Data.IletisimBaslik,
                        IletisimAciklama: res.data.Data.IletisimAciklama,
                        HaberlerBaslik: res.data.Data.HaberlerBaslik,
                        HaberlerAciklama: res.data.Data.HaberlerAciklama,
                        SubelerBaslik: res.data.Data.SubelerBaslik,
                        SubelerAciklama: res.data.Data.SubelerAciklama,
                        PopupGoster: res.data.Data.PopupGoster
                    };
                    resolve("Ayar bilgileri getirildi...");
                }
                else {
                    notify(res.data.ErrorMessage, 'error', 500);
                    reject(res.data.ErrorMessage);
                }
            })
                .catch(error => {
                    reject(error);
                })
        });
    }

    async kaydet() {
        return new Promise((resolve, reject) => {
            service.post(siteApi + "websiteayar/siteAyarKaydet",
                this.formData,
                {
                    headers: {
                        'Authorization': 'Bearer ' + Globals.Token
                    }
                })
                .then(res => {
                    if (res.data.Success) {
                        notify("site bilgileriniz kaydedildi", "success", 2000);
                        resolve("site ayarları kaydedildi...");

                    }
                    else {
                        notify(res.data.ErrorMessage, "error", 2000);
                        reject(res.data.ErrorMessage);
                    }
                }).catch(error => {
                    reject(error);
                });
        });
    }

    async setLoadingText() {
        this.setState({
            loadingText: ""
        });
    }
    //#endregion

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-12">
                        {this.state.loadingText}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Toolbar>
                            <Item location="after"
                                widget="dxButton"
                                options={{
                                    type: "default",
                                    hint: "Kaydet",
                                    text: "Kaydet",
                                    icon: "check",
                                    onClick: async (e) => {

                                        await this.kaydet().then(res => {
                                            console.log(res);
                                        }).catch(error => {
                                            alert(error);
                                        });

                                        await this.getSiteAyar().then(res => {
                                            console.log(res);
                                        }).catch(error => {
                                            alert(error);
                                        });

                                        await this.setLoadingText();
                                    }
                                }} />
                            <Item location="after"
                                widget="dxButton"
                                options={{
                                    type: "danger",
                                    hint: "İptal",
                                    text: "İptal",
                                    icon: "runner"
                                }} />
                        </Toolbar>
                        <Form formData={this.formData}>
                            <GroupItem cssClass="first-group" colCount={2} caption="Site Ayarları">
                                <SimpleItem dataField={"SiteBaslik"} editorOptions={{
                                    placeholder: "Site Başlığı"
                                }}>
                                    <Label text="Site Başlığı" />
                                </SimpleItem>
                                <SimpleItem dataField={"EmailAdresi"} editorOptions={{
                                    placeholder: "Email Adresi"
                                }}
                                >
                                    <Label text="Email Adresi" />
                                </SimpleItem>
                                <SimpleItem dataField={"Telefon"}>
                                    <Label text="Telefon" />
                                </SimpleItem>
                                <SimpleItem dataField={"WhatsappTel"}>
                                    <Label text="Whatsapp Tel" />
                                </SimpleItem>
                                <SimpleItem dataField={"FacebookAdresi"}
                                    editorOptions={{
                                        placeholder: "Facebook Adresi"
                                    }}>
                                    <Label text="Facebook Adresi" />
                                </SimpleItem>
                                <SimpleItem dataField={"TwitterAdresi"}
                                    editorOptions={{
                                        placeholder: "Twitter Adresi"
                                    }}>
                                    <Label text="Twitter Adresi" />
                                </SimpleItem>
                                <SimpleItem dataField={"InstagramAdresi"}
                                    editorOptions={{
                                        placeholder: "Instagram Adresi"
                                    }}>
                                    <Label text="Instagram Adresi" />
                                </SimpleItem>
                                <SimpleItem dataField={"LinkedinAdresi"}
                                    editorOptions={{
                                        placeholder: "Linkedin Adresi"
                                    }}>
                                    <Label text="Linkedin Adresi" />
                                </SimpleItem>
                                <SimpleItem dataField={"DribbleAdresi"}
                                    editorOptions={{
                                        placeholder: "Dribble Adresi"
                                    }}>
                                    <Label text="Dribble Adresi" />
                                </SimpleItem>
                                <SimpleItem dataField={"YoutubeAdresi"}
                                    editorOptions={{
                                        placeholder: "Youtube Adresi"
                                    }}>
                                    <Label text="Youtube Adresi" />
                                </SimpleItem>
                                <SimpleItem dataField={"Koordinat"}
                                    editorOptions={{
                                        placeholder: "Koordinat"
                                    }}>
                                    <Label text="Koordinat" />
                                </SimpleItem>
                                <SimpleItem dataField={"SayfaIciLink"} editorType="dxCheckBox">
                                    <Label text="Linkler Sayfa İçi Açılsın" />
                                </SimpleItem>
                                <SimpleItem dataField={"PopupGoster"} editorType="dxCheckBox">
                                    <Label text="Açılışta Popup Göster" />
                                </SimpleItem>
                                <SimpleItem dataField={"File"}
                                    editorType="dxFileUploader"
                                    editorOptions={{
                                        uploadUrl: siteApi + "websiteayar/LogoUpload",
                                        uploadMode: 'instantly',
                                        uploadHeaders: this.headers,
                                        name: 'myFile',
                                        onValueChanged: this.onUploaded
                                    }}>
                                    <Label text="Logo seçiniz" />
                                </SimpleItem>
                            </GroupItem>
                            <GroupItem cssClass="first-group" colCount={1} caption="Footer">
                                <SimpleItem dataField={"FooterAdres"}>
                                    <Label text="Adres" />
                                </SimpleItem>
                                <SimpleItem dataField={"FooterCopyright"}>
                                    <Label text="Copyright Adresi" />
                                </SimpleItem>
                                <SimpleItem dataField={"FileArkaPlan"}
                                    editorType="dxFileUploader"
                                    editorOptions={{
                                        uploadUrl: siteApi + "websiteayar/FooterArkaPlanUpload",
                                        uploadMode: 'instantly',
                                        uploadHeaders: this.headers,
                                        name: 'myFileFooterArkaPlan',
                                        onValueChanged: this.onUploadedFooterArkaPlan
                                    }}>
                                    <Label text="Arkaplan seçiniz" />
                                </SimpleItem>
                            </GroupItem>
                            <GroupItem cssClass="first-group" colCount={1} caption="Başvuru Sayfası Bilgileri">
                                <SimpleItem dataField={"BasvuruBaslik"}>
                                    <Label text="Başlık" />
                                </SimpleItem>
                                <SimpleItem dataField={"BasvuruAciklama"}>
                                    <Label text="Açıklama" />
                                </SimpleItem>
                                <SimpleItem dataField={"FileBasvuruTitle"}
                                    editorType="dxFileUploader"
                                    editorOptions={{
                                        uploadUrl: siteApi + "websiteayar/BasvuruTitleUpload",
                                        uploadMode: 'instantly',
                                        uploadHeaders: this.headers,
                                        name: 'myFileBasvuruTitle',
                                        onValueChanged: this.onUploadedBasvuruTitle
                                    }}>
                                    <Label text="Başvuru resmi seçiniz" />
                                </SimpleItem>
                            </GroupItem>
                            <GroupItem cssClass="first-group" colCount={1} caption="Kurye Çağır Sayfası Bilgileri">
                                <SimpleItem dataField={"KuryeBaslik"}>
                                    <Label text="Başlık" />
                                </SimpleItem>
                                <SimpleItem dataField={"KuryeAciklama"}>
                                    <Label text="Açıklama" />
                                </SimpleItem>
                                <SimpleItem dataField={"FileKuryeTitle"}
                                    editorType="dxFileUploader"
                                    editorOptions={{
                                        uploadUrl: siteApi + "websiteayar/KuryeTitleUpload",
                                        uploadMode: 'instantly',
                                        uploadHeaders: this.headers,
                                        name: 'myFileKuryeTitle',
                                        onValueChanged: this.onUploadedBasvuruTitle
                                    }}>
                                    <Label text="Kurye çağır resmi seçiniz" />
                                </SimpleItem>
                            </GroupItem>

                            <GroupItem cssClass="first-group" colCount={1} caption="İnsan Kaynakları Sayfası Bilgileri">
                                <SimpleItem dataField={"IkBaslik"}>
                                    <Label text="Başlık" />
                                </SimpleItem>
                                <SimpleItem dataField={"IkAciklama"}>
                                    <Label text="Açıklama" />
                                </SimpleItem>
                                <SimpleItem dataField={"FileIkTitle"}
                                    editorType="dxFileUploader"
                                    editorOptions={{
                                        uploadUrl: siteApi + "websiteayar/IkTitleUpload",
                                        uploadMode: 'instantly',
                                        uploadHeaders: this.headers,
                                        name: 'myFileIkTitle',
                                        onValueChanged: this.onUploadedIkTitle
                                    }}>
                                    <Label text="Ik resmi seçiniz" />
                                </SimpleItem>
                            </GroupItem>
                            <GroupItem cssClass="first-group" colCount={1} caption="İletişim Sayfası Bilgileri">
                                <SimpleItem dataField={"IletisimBaslik"}>
                                    <Label text="Başlık" />
                                </SimpleItem>
                                <SimpleItem dataField={"IletisimAciklama"}>
                                    <Label text="Açıklama" />
                                </SimpleItem>
                            </GroupItem>
                            <GroupItem cssClass="first-group" colCount={1} caption="Şubeler Sayfası Bilgileri">
                                <SimpleItem dataField={"SubelerBaslik"}>
                                    <Label text="Başlık" />
                                </SimpleItem>
                                <SimpleItem dataField={"SubelerAciklama"}>
                                    <Label text="Açıklama" />
                                </SimpleItem>
                            </GroupItem>
                            <GroupItem cssClass="first-group" colCount={1} caption="Haberler Sayfası Bilgileri">
                                <SimpleItem dataField={"HaberlerBaslik"}>
                                    <Label text="Başlık" />
                                </SimpleItem>
                                <SimpleItem dataField={"HaberlerAciklama"}>
                                    <Label text="Açıklama" />
                                </SimpleItem>
                            </GroupItem>
                        </Form>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default withRouter(SiteAyarlariPage);