import * as React from "react";
import { siteApi, YuklenenLogoUrl, YuklenenVideoUrl } from '../Utils';
import service from 'axios';
import notify from 'devextreme/ui/notify';
import Logo from '../components/Header/Logo';
import MenuBtn from '../components/Header/MenuBtn';
import RightTop from '../components/Header/RightTop';
import RightBottom from '../components/Header/RightBottom';
import Slider from '../components/Slayt/Slider';
import KargoTakipForm from '../components/Forms/KargoTakipForm';
import DortluBolme from '../components/Widgets/DortluBolme';
import TekliBolme from '../components/Widgets/TekliBolme';
import Banner from '../components/Widgets/Banner';
import UcluHaberKutusu from '../components/Widgets/UcluHaberKutusu';
import MainFooter from '../components/Footer/MainFooter';
import Whatsapp from '../components/Widgets/Whatsapp';
import { Popup } from 'devextreme-react';
import { Position } from 'devextreme-react/data-grid';
import ScrollView from 'devextreme-react/scroll-view';
import { withRouter } from "react-router";

class HomePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            EmailAdresi: "",
            Telefon: "",
            TelefonBitisik: "",
            SiteBaslik: "",
            FacebookAdresi: "",
            YoutubeAdresi: "",
            TwitterAdresi: "",
            InstagramAdresi: "",
            LinkedinAdresi: "",
            DribbleAdresi: "",
            FooterAdres: "",
            FooterCopyright: "",
            FooterArkaPlan: "",
            SayfaIciLink: false,
            Koordinat: "",
            WhatsappTel: "",
            Logo: "",
            EbultenMail: null,
            Slaytlar: [],
            Sayfalar: [],
            DortluBolmeler: [],
            TekliBolmeler: [],
            UcluHaberler: [],
            Bannerlar: [],
            Bolmeler: [],
            Subeler: [],
            FormAdSoyad: "",
            FormEmail: "",
            FormTelefon: "",
            FormMesaj: "",
            EkranBaslik: "",
            VideoKaynak: "",
            VideoKaynakLocal: "",
            IsVideo: false,
            LocalVideo: false,
            Resim: "",
            PopupId: 0,
            popup: false
        };
        //this.getData();

    }

    //#region form fonksiyonları

    popupGizle() {
        this.setState({
            popup: false
        });
    }

    async getData() {
        await this.getSetting()
            .then(res => {


            })
            .catch(error => {
                console.log(error);
                notify(error, "error", 3000);
            });

        await this.getSitePage()
            .then(res => {

            })
            .catch(error => {
                console.log(error);
                notify(error, "error", 3000);
            });

        await this.getSlides()
            .then(res => {

            })
            .catch(error => {
                console.log(error);
                notify(error, "error", 3000);
            });

        await new Promise((resolve, reject) => {
            try {
                window.owlCarousel = true;
                resolve("Slide için gerekli datalar oluşturuldu.")
            } catch (error) {
                reject(error);
            }
        }).then(res => {

        }).catch(error => {
            console.log(error);
        });

        await this.getWidgets()
            .then(res => {

            })
            .catch(error => {
                console.log(error);
                notify(error, "error", 3000);
            });

    }

    //#endregion

    //#region ui fonksiyonları

    //#endregion

    //#region servis metodları

    async getSitePage() {
        return new Promise((resolve, reject) => {
            service.get(siteApi + "arayuz/getSiteSayfa", null)
                .then(res => {
                    if (res.data.Success) {
                        this.setState({
                            Sayfalar: res.data.Data
                        });
                        resolve("Sayfa bilgileri getirildi.")
                    }
                    else
                        reject(res.data.ErrorMessage);
                }).catch(error => {
                    reject(error);
                });
        })
    }

    async getSetting() {
        return new Promise((resolve, reject) => {
            service.get(siteApi + "arayuz/getSiteAyar", null)
                .then(res => {
                    if (res.data.Success) {
                        const ayar = res.data.Data[0];
                        this.setState({
                            FacebookAdresi: ayar.facebookadresi,
                            TwitterAdresi: ayar.twitteradresi,
                            YoutubeAdresi: ayar.youtubeadresi,
                            LinkedinAdresi: ayar.linkedinadresi,
                            InstagramAdresi: ayar.instagramadresi,
                            WhatsappTel: ayar.whatsapptel,
                            Telefon: ayar.telefon,
                            SayfaIciLink: ayar.sayfaicilink,
                            TelefonBitisik: ayar.telefon.trim(),
                            popup: ayar.popupgoster
                        });
                        try {
                            if (ayar.popupgoster) {
                                service.get(siteApi + "arayuz/GetSitePopup", null)
                                    .then(resPopup => {
                                        if (resPopup.data.Success) {
                                            this.setState({
                                                VideoKaynak: resPopup.data.Data.VideoKaynak,
                                                Resim: resPopup.data.Data.Resim,
                                                IsVideo: resPopup.data.Data.IsVideo,
                                                LocalVideo: resPopup.data.Data.LocalVideo,
                                                VideoKaynakLocal: resPopup.data.Data.VideoKaynakLocal,
                                                PopupId: resPopup.data.Data.Id
                                            });
                                        }
                                    }).catch(error => {

                                    });
                            }
                        } catch (error) {

                        }
                        resolve("Ayar bilgisi getirildi...");
                    } else {
                        notify(res.data.ErrorMessage, "error", 3000);
                        reject(res.data.ErrorMessage);
                    }
                })
                .catch(error => {
                    reject(error);
                })
        });
    }

    async getSlides() {
        return new Promise((resolve, reject) => {
            service.get(siteApi + "arayuz/getSiteSlayt", null)
                .then(res => {
                    if (res.data.Success) {
                        const data = res.data.Data.sort((a, b) => (a.sira > b.sira ? 1 : -1));
                        this.setState({
                            Slaytlar: data
                        });
                        resolve("Slaytlar getirildi...")
                    }
                    else {
                        reject(res.data.ErrorMessage);
                    }
                }).catch(error => {
                    reject(error);
                });
        })
    }

    async getWidgets() {
        return new Promise((resolve, reject) => {
            service.get(siteApi + "arayuz/getWidgets", null)
                .then(res => {
                    if (res.data.Success) {
                        this.setState({
                            Bolmeler: res.data.Data.Bolmeler,
                            Bannerlar: res.data.Data.Bannerlar,
                            DortluBolmeler: res.data.Data.DortluBolmeler,
                            TekliBolmeler: res.data.Data.TekliBolmeler,
                            UcluHaberler: res.data.Data.UcluHaberler,
                            Subeler: res.data.Data.Subeler
                        });
                        resolve("bölmeler getirildi...")
                    }
                    else {
                        reject(res.data.ErrorMessage);
                    }
                }).catch(error => {
                    reject(error);
                });
        })
    }

    popupRender() {
        if (this.state.PopupId === 0 | this.state.PopupId === undefined | this.state.PopupId === null) {

            return (<React.Fragment></React.Fragment>);
        }
        else {
            if (this.state.IsVideo) {
                if (this.state.LocalVideo) {

                    return (<React.Fragment>
                        <video width="100%" height="500px" autoPlay={true} controls={"controls"}>
                            <source src={YuklenenVideoUrl + this.state.VideoKaynakLocal} type="video/mp4" />
                                        Your browser does not support the video tag.
                            </video>
                    </React.Fragment>);
                }
                else {

                    return (<React.Fragment>
                        <div dangerouslySetInnerHTML={{ __html: this.state.VideoKaynak }} ></div>
                    </React.Fragment>);
                }

            } else {
                return (
                    <React.Fragment>
                        <img alt="Byexpress" src={YuklenenLogoUrl + this.state.Resim} style={{ width: "100%", height: "100%" }}></img>
                    </React.Fragment>
                )
            }
        }
    }
    //#endregion

    componentDidMount() {
        this.getData();
    }

    render() {
        return (
            <React.Fragment>
                <section id="homePage">
                    <div className="headerContainer">
                        <div className="container d-flex align-items-end justify-content-between">
                            <Logo Logo="/images/logo/logo.png" Baslik="Byepxress"></Logo>
                            <div className="headerRight">
                                <MenuBtn />
                                <RightTop
                                    facebookadresi={this.state.FacebookAdresi}
                                    twitteradresi={this.state.TwitterAdresi}
                                    youtubeadresi={this.state.YoutubeAdresi}
                                    linkedinadresi={this.state.LinkedinAdresi}
                                    instagramadresi={this.state.InstagramAdresi}
                                    telefonbitisik={this.state.TelefonBitisik}
                                    telefon={this.state.Telefon}
                                    SayfaIciLink={this.state.SayfaIciLink} />
                                <RightBottom Sayfalar={this.state.Sayfalar} />
                            </div>
                        </div>
                    </div>

                    <div className="homeSliderContainer d-flex align-items-center justify-content-between">
                        <div className="homeSlider owl-carousel" ref="slideContainer">
                            <Slider Slaytlar={this.state.Slaytlar} />
                        </div>
                    </div>

                    <div className="homeArama">
                        <div className="container">
                            <KargoTakipForm />
                        </div>
                    </div>

                    {/* widgetlar başlangıç  */}
                    {this.state.Bolmeler.sort((a, b) => (a.sira > b.sira ? 1 : -1))
                        .map((bolme, i) => {
                            if (bolme.webdortlusection_id !== null & (bolme.haberkutusu === false | bolme.haberkutusu === null)) {
                                const dortluBolme = this.state.DortluBolmeler.find(x => x.id === bolme.webdortlusection_id);
                                if (dortluBolme === undefined) {
                                    return null;
                                } else {
                                    return <DortluBolme key={i}
                                        Baslik={dortluBolme.baslik}
                                        Background={dortluBolme.background}
                                        KutuBirIkon={dortluBolme.kutubirikon}
                                        KutuBirBaslik={dortluBolme.kutubirbaslik}
                                        KutuBirIcerik={dortluBolme.kutubiricerik}
                                        KutuIkiIkon={dortluBolme.kutuikiikon}
                                        KutuIkiBaslik={dortluBolme.kutuikibaslik}
                                        KutuIkiIcerik={dortluBolme.kutuikiicerik}
                                        KutuUcIkon={dortluBolme.kutuucikon}
                                        KutuUcBaslik={dortluBolme.kutuucbaslik}
                                        KutuUcIcerik={dortluBolme.kutuucicerik}
                                        KutuDortIkon={dortluBolme.kutudortikon}
                                        KutuDortBaslik={dortluBolme.kutudortbaslik}
                                        KutuDortIcerik={dortluBolme.kutudorticerik}
                                    />
                                }
                            }
                            else if (bolme.webteklisection_id !== null & (bolme.haberkutusu === false | bolme.haberkutusu === null)) {
                                const tekliBolme = this.state.TekliBolmeler.find(x => x.id === bolme.webteklisection_id);
                                if (tekliBolme === undefined) {
                                    return null;
                                } else {
                                    return <TekliBolme
                                        key={i}
                                        Background={tekliBolme.background}
                                        Baslik={tekliBolme.baslik}
                                        Aciklama={tekliBolme.aciklama}
                                        AcilisSekli={tekliBolme.acilissekli}
                                        Url={tekliBolme.url}
                                        Resim={tekliBolme.resim}
                                        ResimSagaYasla={tekliBolme.resimsagayasla}
                                    />
                                }
                            }
                            else if (bolme.webbannersection_id !== null & (bolme.haberkutusu === false | bolme.haberkutusu === null)) {
                                const banner = this.state.Bannerlar.find(x => x.id === bolme.webbannersection_id);
                                if (banner === undefined) {
                                    return null;
                                } else {
                                    return <Banner
                                        key={i}
                                        Background={banner.background === "" ? null : banner.background}
                                        Baslik={banner.baslik}
                                        Aciklama={banner.aciklama}
                                        AcilisSekli={banner.acilissekli}
                                        Url={banner.url}
                                    />
                                }
                            }
                            else if (bolme.haberkutusu === true) {
                                return <UcluHaberKutusu
                                    key={i}
                                    UcluHaberler={this.state.UcluHaberler}
                                />
                            }
                            else
                                return null;
                        })}
                    {/* widgetlar bitiş  */}

                    {/* whatsapp başlangıç  */}
                    <Whatsapp whatsapptel={this.state.WhatsappTel}></Whatsapp>
                    {/* whatsapp bitiş  */}

                    {/* footer başlangıç  */}
                    <MainFooter
                        Menuler={this.state.Sayfalar}
                        Subeler={this.state.Subeler}
                        FacebookAdresi={this.state.FacebookAdresi}
                        TwitterAdresi={this.state.TwitterAdresi}
                        YoutubeAdresi={this.state.YoutubeAdresi}
                        LinkedinAdresi={this.state.LinkedinAdresi}
                        InstagramAdresi={this.state.InstagramAdresi}
                        SayfaIciLink={this.state.SayfaIciLink} />
                    {/* footer bitiş  */}
                </section>
                <Popup showTitle={true} shading={false} dragEnabled={true} ref={(ref) => this.popupForm = ref}
                    close_ClickOnOutsideClick={false} popupOptions={{ animation: null }} showCloseButton={false} height={"600px"}
                    onHiding={this.popupGizle} visible={this.state.popup}
                    toolbarItems={
                        [
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Kapat', type: 'danger', hint: 'Kapat', icon: 'close'
                                    , onClick: () => { this.popupGizle(); }
                                }
                            }

                        ]
                    }
                >
                    <Position my={'center'} at={'center'} of={window} />
                    <ScrollView id={'scrollview'} showScrollBarMode='onScroll' scrollByContent={true} scrollByThumb={true} bounceEnabled={false}>
                        <div className="row">
                            <div className="col-md-12">
                                {
                                    this.popupRender()
                                }
                            </div>
                        </div>
                    </ScrollView>
                </Popup>

            </React.Fragment>
        );
    }
}
export default withRouter(HomePage);