import React from 'react';
import { coreApi } from '../../Utils';
import Globals from '../../Globals';
import service from 'axios';
import DataGrid, {
    Selection, Export, ColumnChooser, ColumnFixing, Column, Editing, Paging, Pager, FilterRow, LoadPanel, Grouping, GroupPanel
} from 'devextreme-react/data-grid';
import { Popup } from 'devextreme-react';
import ScrollView from 'devextreme-react/scroll-view';
import { Position } from 'devextreme-react/data-grid';
import Form, { SimpleItem, GroupItem } from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import { alert } from 'devextreme/ui/dialog';
import { withRouter } from "react-router";
class RolPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            initial: 'state',
            roller: [],
            popup: false,
            ekranlar: [],
            loadingText: "",
            toolbarDisabled: false
        };
        this.popupForm = null;
        this.mainDataGrid = null;
        this.dataGridEkran = null;
        this.secilenEkranlar = [];
        this.satir = null;
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.ekranSecimiChanged = this.ekranSecimiChanged.bind(this);
        this.onInitialized = this.onInitialized.bind(this);
        this.kayitDuzenle = this.kayitDuzenle.bind(this);
        this.kayitYenile = this.kayitYenile.bind(this);

        this.kayitYenile();
    }

    //#region form fonksiyonları

    async popupAc() {
        this.setState({
            popup: true,
            toolbarDisabled: true
        })
    }

    async popupGizle() {
        this.secilenEkranlar = [];
        this.setState(
            {
                popup: false,
                toolbarDisabled: false
            });
        this.satirIptal();
    };

    //#endregion

    //#region servis fonksiyonları

    async roller() {
        return new Promise((resolve, reject) => {
            service.get(coreApi + "rol/getRoller",
                {
                    headers: {
                        'Authorization': 'Bearer ' + Globals.Token
                    }
                })
                .then(res => {
                    if (res.data.Success) {
                        this.setState({
                            roller: res.data.Data
                        });
                        resolve("Rol bilgileri getirildi...");
                    }
                    else {
                        notify(res.data.ErrorMessage, "error", 5000);
                        reject(res.data.ErrorMessage);
                    }
                })
                .catch(error => {
                    reject("Teknik bir hata oluştu," + error);
                });
        });
    }

    async ekranlar() {
        return new Promise((resolve, reject) => {
            service.get(coreApi + "rol/getEkranlar",
                {
                    headers: {
                        'Authorization': 'Bearer ' + Globals.Token
                    }
                })
                .then(res => {
                    if (res.data.Success) {
                        this.setState({
                            ekranlar: res.data.Data
                        });
                        resolve("Ekran bilgileri getirildi...");
                    }
                    else {
                        notify(res.data.ErrorMessage, "error", 5000);
                        reject(res.data.ErrorMessage);
                    }
                })
                .catch(error => {
                    reject(error);
                });

        });
    }

    async getSatir(rolId) {
        return new Promise((resolve, reject) => {
            service.get(coreApi + "rol/getRolEkranByRolId/" + rolId,
                {
                    headers: {
                        'Authorization': 'Bearer ' + Globals.Token
                    }
                })
                .then(res => {
                    if (res.data.Success) {
                        let arrayList = [];
                        res.data.Data.forEach((item, index) => {
                            arrayList.push(item.Ekran.Id)
                        });
                        this.dataGridEkran.instance.selectRows(arrayList);
                        resolve("Düzenleme ekranı hazırlandı");
                    }
                    else {
                        notify(res.data.ErrorMessage, 'error', 500);
                        reject(res.data.ErrorMessage);
                    }
                }).catch(error => {
                    reject(error);
                });
        });
    }

    //#endregion

    //#region toolbar fonksiyonları

    async kayitYenile() {
        this.setState({
            toolbarDisabled: true,
            loadingText: "Roller yükleniyor..."
        })

        if (this.state.roller.length === 0) {
            await this.roller().then(res => {
                console.log(res);
            }).catch(error => {
                alert(error);
            });
        }

        if (this.state.ekranlar.length === 0) {
            await this.ekranlar().then(res => {
                console.log(res);
            }).catch(error => {
                alert(error);
            });
        }


        this.setState({
            toolbarDisabled: false,
            loadingText: ""
        });
        this.satirIptal();
    }

    async kayitDuzenle(e) {
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            this.getSatir(satir.Id)
                .then(res => {
                    console.log(res);
                    this.popupAc();
                })
                .catch(error => {
                    alert(error);
                })
        }
    }

    //#endregion

    //#region grid fonksiyonları

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(

            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default',
                    hint: 'Düzenle',
                    icon: 'edit',
                    disabled: this.state.toolbarDisabled,
                    onClick: this.kayitDuzenle
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', hint: 'Yenile', icon: 'refresh',
                    disabled: this.state.toolbarDisabled,
                    onClick: this.kayitYenile
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', icon: 'runner', hint: 'Ana Sayfa',
                    disabled: this.state.toolbarDisabled,
                    onClick: () => {
                        this.props.history.push("/home");
                    }
                }
            }
        );
    }

    onSelectionChanged(selectedItems) {
        this.satir = selectedItems.selectedRowsData[0];
    }

    ekranSecimiChanged() {
        this.secilenEkranlar = this.dataGridEkran.instance.getSelectedRowsData();
    }

    onInitialized(e) {
        this.mainDataGrid = e.component;
    }


    async satirIptal() {
        if (this.mainDataGrid.instance !== null) {
            this.mainDataGrid.instance.clearSelection();
            this.mainDataGrid.instance.option("focusedRowIndex", -1);
        }
    }

    //#endregion


    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-12">
                        {this.state.loadingText}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <h3>Roller</h3>
                        <DataGrid
                            showBorders={true}
                            showRowLines={true}
                            dataSource={this.state.roller}
                            columnHidingEnabled={true}
                            filterPanel={{ visible: true }}
                            filterRow={{ visible: true }}
                            columnAutoWidth={true}
                            selection={{ mode: 'single' }}
                            autoNavigateToFocusedRow={true}
                            focusedRowEnabled={true}
                            hoverStateEnabled={true}
                            keyExpr="Id"
                            paging={{
                                pageSize: 20
                            }}
                            pager={{
                                showPageSizeSelector: true,
                                allowedPageSizes: [5, 10, 50, 75, 100],
                                showInfo: true
                            }}
                            onToolbarPreparing={this.onToolbarPreparing}
                            onSelectionChanged={this.onSelectionChanged}
                            onInitialized={this.onInitialized}
                            ref={(ref) => this.mainDataGrid = ref}
                        >
                            <Export enabled={true} fileName={'Roller'} allowExportSelectedData={false} />
                            <ColumnChooser enabled={true} />
                            <Editing mode={'popup'} />
                            <Grouping contextMenuEnabled={true} expandMode="rowClick" />
                            <GroupPanel visible={true} emptyPanelText="Gruplamak için sütün başlıklarını buraya sürükleyiniz." />
                            <ColumnFixing enabled={true} />
                            <LoadPanel enabled={true} />
                            <FilterRow visible={false} applyFilter={'auto'} />
                            <Paging defaultPageSize={15} />
                            <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={false} />
                            <Column dataField={'Id'} caption="Kayıt No" visible={false} />
                            <Column dataField={'Adi'} caption="Rol" />
                        </DataGrid>
                        <Popup showTitle={true} shading={false} dragEnabled={true} ref={(ref) => this.popupForm = ref}
                            close_ClickOnOutsideClick={false} popupOptions={{ animation: null }} showCloseButton={false}
                            onHiding={this.popupGizle} visible={this.state.popup}
                            toolbarItems={
                                [
                                    {
                                        location: 'after', widget: 'dxButton', options: {
                                            text: 'Kaydet', type: 'success', hint: 'Kaydet', icon: 'check'
                                            , onClick: (e) => {
                                                var data = {
                                                    Ekranlar: this.secilenEkranlar
                                                }
                                                service.post(coreApi + 'Rol/RolEkranSave/' + this.satir.Id,
                                                    data,
                                                    {
                                                        headers: {
                                                            'Authorization': 'Bearer ' + Globals.Token
                                                        }
                                                    }
                                                )
                                                    .then(res => {
                                                        if (res.data.Success) {
                                                            this.popupGizle();
                                                        }
                                                        else
                                                            notify(res.data.ErrorMessage, 'error', 5000);
                                                    })
                                            }
                                        }
                                    },
                                    {
                                        location: 'after', widget: 'dxButton', options: {
                                            text: 'Vazgeç', type: 'danger', hint: 'Çıkış', icon: 'runner'
                                            , onClick: () => { this.popupGizle(); }
                                        }
                                    }

                                ]
                            }
                        >
                            <Position my={'center'} at={'center'} of={window} />
                            <ScrollView id={'scrollview'} showScrollBarMode='onScroll' scrollByContent={true} scrollByThumb={true} bounceEnabled={false}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Form formData={this.formData}>
                                            <GroupItem cssClass="first-group" colCount={1} caption="Ekranlar">
                                                <SimpleItem>
                                                    <DataGrid
                                                        showBorders={true}
                                                        showRowLines={true}
                                                        dataSource={this.state.ekranlar}
                                                        repaintChangesOnly={false}
                                                        columnHidingEnabled={true}
                                                        filterPanel={{ visible: true }}
                                                        filterRow={{ visible: true }}
                                                        columnAutoWidth={true}
                                                        autoNavigateToFocusedRow={true}
                                                        focusedRowEnabled={true}
                                                        hoverStateEnabled={true}
                                                        rowAlternationEnabled={false}
                                                        onSelectionChanged={this.ekranSecimiChanged}
                                                        keyExpr="Id"
                                                        paging={{
                                                            pageSize: 20
                                                        }}
                                                        pager={{
                                                            showPageSizeSelector: true,
                                                            allowedPageSizes: [5, 10, 50, 75, 100],
                                                            showInfo: true
                                                        }}
                                                        ref={(ref) => this.dataGridEkran = ref}
                                                    >

                                                        <Paging defaultPageSize={15} />
                                                        <Selection mode="multiple"
                                                            selectAllMode="allPages"
                                                            showCheckBoxesMode="onClick" />
                                                        <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={false} />
                                                        <Column dataField={'Id'} caption="Id" visible={false} />
                                                        <Column dataField={'Href'} caption="Href" />
                                                        <Column dataField={'Adi'} caption="Açıklama" />
                                                    </DataGrid>
                                                </SimpleItem>
                                            </GroupItem>
                                        </Form>
                                    </div>
                                </div>
                            </ScrollView>
                        </Popup>


                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default withRouter(RolPage);
