import React from 'react';
import { siteApi } from '../../Utils';
import Globals from '../../Globals';
import service from 'axios';
import notify from 'devextreme/ui/notify';
import DataGrid, {
    Export, ColumnChooser, ColumnFixing, Column, Editing, Paging, Pager, FilterRow, LoadPanel, Grouping, GroupPanel
} from 'devextreme-react/data-grid';
import { Popup } from 'devextreme-react';
import ScrollView from 'devextreme-react/scroll-view';
import { Position } from 'devextreme-react/data-grid';
import Form, { SimpleItem, GroupItem, Label } from 'devextreme-react/form';
import { withRouter } from "react-router";
import { confirm, alert } from 'devextreme/ui/dialog';
class DortluSectionPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingText: "",
            bolmeler: [],
            toolbarDisabled: false,
            secilenBackground: null,
            secilenIkon1: null,
            secilenIkon2: null,
            secilenIkon3: null,
            secilenIkon4: null
        }
        this.satir = null;
        this.mainDataGrid = null;
        this.onInitialized = this.onInitialized.bind(this);
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.kayitYeni = this.kayitYeni.bind(this);
        this.kayitDuzenle = this.kayitDuzenle.bind(this);
        this.kayitAktifYap = this.kayitAktifYap.bind(this);
        this.kayitPasifYap = this.kayitPasifYap.bind(this);
        this.kayitSil = this.kayitSil.bind(this);
        this.kayitYenile = this.kayitYenile.bind(this);
        this.headers = { 'Authorization': 'Bearer ' + Globals.Token };
        this.onBackgroundUploaded = this.onBackgroundUploaded.bind(this);
        this.onIkon1Uploaded = this.onIkon1Uploaded.bind(this);
        this.onIkon2Uploaded = this.onIkon2Uploaded.bind(this);
        this.onIkon3Uploaded = this.onIkon3Uploaded.bind(this);
        this.onIkon4Uploaded = this.onIkon4Uploaded.bind(this);
        this.formData = {
            Id: 0,
            Baslik: "",
            Background: "",
            KutuBirIkon: "",
            KutuIkiIkon: "",
            KutuUcIkon: "",
            KutuDortIkon: "",
            KutuBirBaslik: "",
            KutuIkiBaslik: "",
            KutuUcBaslik: "",
            KutuDortBaslik: "",
            KutuBirIcerik: "",
            KutuIkiIcerik: "",
            KutuUcIcerik: "",
            KutuDortIcerik: "",
            Deleted: false,
            RowVersion: 0
        };

        this.kayitYenile().then(res => {
            console.log(res);

        }).catch(error => {
            alert(error);
        });
    }

    //#region form fonksiyonları

    onBackgroundUploaded(e) {
        var res = JSON.parse(e.request.response);
        if (!res.Success) {
            notify("Background resminiz yüklenemedi," + res.ErrorMessage, "error", 5000);

            e.component.reset();
        }
        else {
            notify("Background resminiz yüklendi", "success", 5000);
            this.setState({
                secilenBackground: res.Data
            });
            this.formData.Background = res.Data;
            e.component.reset();
        }
    }

    onIkon1Uploaded(e) {
        var res = JSON.parse(e.request.response);
        if (!res.Success) {
            notify("Ikon 1 resminiz yüklenemedi," + res.ErrorMessage, "error", 5000);

            e.component.reset();
        }
        else {
            notify("Ikon 1 resminiz yüklendi", "success", 5000);
            this.setState({
                secilenIkon1: res.Data
            });
            this.formData.KutuBirIkon = res.Data;
            e.component.reset();
        }
    }

    onIkon2Uploaded(e) {
        var res = JSON.parse(e.request.response);
        if (!res.Success) {
            notify("Ikon 2 resminiz yüklenemedi," + res.ErrorMessage, "error", 5000);

            e.component.reset();
        }
        else {
            notify("Ikon 2 resminiz yüklendi", "success", 5000);
            this.setState({
                secilenIkon2: res.Data
            });
            this.formData.KutuIkiIkon = res.Data;
            e.component.reset();
        }
    }

    onIkon3Uploaded(e) {
        var res = JSON.parse(e.request.response);
        if (!res.Success) {
            notify("Ikon 3 resminiz yüklenemedi," + res.ErrorMessage, "error", 5000);

            e.component.reset();
        }
        else {
            notify("Ikon 3 resminiz yüklendi", "success", 5000);
            this.setState({
                secilenIkon3: res.Data
            });
            this.formData.KutuUcIkon = res.Data;
            e.component.reset();
        }
    }

    onIkon4Uploaded(e) {
        var res = JSON.parse(e.request.response);
        if (!res.Success) {
            notify("Ikon 4 resminiz yüklenemedi," + res.ErrorMessage, "error", 5000);

            e.component.reset();
        }
        else {
            notify("Ikon 4 resminiz yüklendi", "success", 5000);
            this.setState({
                secilenIkon4: res.Data
            });
            this.formData.KutuDortIkon = res.Data;
            e.component.reset();
        }
    }

    popupGizle() {
        this.setState(
            {
                popup: false,
                toolbarDisabled: false
            });
        this.satirIptal();
    };

    popupAc() {
        this.setState(
            {
                popup: true,
                toolbarDisabled: true
            });
    };

    setLoadingText(mesaj) {
        this.setState({
            loadingText: mesaj
        });
    }

    //#endregion

    //#region servis fonksiyonları

    async kaydet() {
        return new Promise((resolve, reject) => {
            service.post(siteApi + "dortlu/kaydet",
                this.formData,
                {
                    headers: {
                        'Authorization': 'Bearer ' + Globals.Token
                    }
                })
                .then(res => {
                    if (res.data.Success) {
                        notify("Dörtlü bölme bilgisi kaydedildi", "success", 2000);
                        resolve("Dörtlü bölme bilgisi kaydedildi...");
                    }
                    else {
                        notify(res.data.ErrorMessage, "error", 2000);
                        reject(res.data.ErrorMessage);
                    }
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    //#endregion

    //#region grid fonksiyonları

    onSelectionChanged(selectedItems) {
        this.satir = selectedItems.selectedRowsData[0];
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', hint: 'Yeni', icon: 'add',
                    disabled: this.state.toolbarDisabled,
                    onClick: (async () => {
                        let isError = false;
                        await this.kayitYeni().then(res => { console.log(res) })
                            .catch(error => {
                                alert(error, "Uyarı");
                                isError = true;
                            });

                        if (!isError)
                            await this.popupAc();
                        else
                            this.satirIptal();
                    })
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', hint: 'Düzenle', icon: 'edit',
                    disabled: this.state.toolbarDisabled,
                    onClick: (async () => {
                        let isError = false;
                        await this.kayitDuzenle().then(res => { console.log(res) })
                            .catch(error => {
                                alert(error, "Uyarı");
                                isError = true;
                            });
                        if (!isError)
                            await this.popupAc();
                        else
                            this.satirIptal();

                    })
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'danger', hint: 'Sil', icon: 'remove',
                    disabled: this.state.toolbarDisabled,
                    onClick: (async () => {
                        let isError = false;

                        await this.kayitSil()
                            .then(res => { console.log(res) })
                            .catch(error => {
                                alert(error, "Uyarı");
                                isError = true;
                            });

                        if (!isError) {
                            await this.kayitYenile()
                                .then(res => {
                                    console.log(res)
                                })
                                .catch(error => {
                                    alert(error, "Uyarı")
                                });
                        }
                        else
                            this.satirIptal();
                    })
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'danger', hint: 'Pasif yap', icon: 'isblank',
                    disabled: this.state.toolbarDisabled,
                    onClick: (async () => {
                        let isError = false;

                        await this.kayitPasifYap()
                            .then(res => { console.log(res) })
                            .catch(error => {
                                alert(error, "Uyarı");
                                isError = true;
                            });

                        if (!isError) {
                            await this.kayitYenile()
                                .then(res => {
                                    console.log(res)
                                })
                                .catch(error => {
                                    alert(error, "Uyarı")
                                });
                        }
                        else
                            this.satirIptal();
                    })
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'success', hint: 'Aktif yap', icon: 'isnotblank',
                    disabled: this.state.toolbarDisabled,
                    onClick: (async () => {
                        let isError = false;

                        await this.kayitAktifYap()
                            .then(res => { console.log(res) })
                            .catch(error => {
                                alert(error, "Uyarı");
                                isError = true;
                            });

                        if (!isError) {
                            await this.kayitYenile()
                                .then(res => {
                                    console.log(res)
                                })
                                .catch(error => {
                                    alert(error, "Uyarı")
                                });
                        }
                        else
                            this.satirIptal();
                    })
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', hint: 'Yenile', icon: 'refresh',
                    disabled: this.state.toolbarDisabled,
                    onClick: () => {
                        this.kayitYenile().then(res => {
                            console.log(res);

                        }).catch(error => {
                            alert(error);
                        });
                    }
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', icon: 'runner', hint: 'Ana Sayfa',
                    disabled: this.state.toolbarDisabled,
                    onClick: () => {
                        alert("not implement");
                    }
                }
            }
        );
    }

    onInitialized(e) {
        this.mainDataGrid = e.component;
    }


    async satirIptal() {
        if (this.mainDataGrid.instance !== null) {
            this.mainDataGrid.instance.clearSelection();
            this.mainDataGrid.instance.option("focusedRowIndex", -1);
        }
    }

    //#endregion

    //#region toolbar fonksiyonları

    async kayitYeni() {
        return new Promise((resolve, reject) => {
            try {
                this.formData = {
                    Id: 0,
                    Baslik: "",
                    Background: "",
                    KutuBirIkon: "",
                    KutuIkiIkon: "",
                    KutuUcIkon: "",
                    KutuDortIkon: "",
                    KutuBirBaslik: "",
                    KutuIkiBaslik: "",
                    KutuUcBaslik: "",
                    KutuDortBaslik: "",
                    KutuBirIcerik: "",
                    KutuIkiIcerik: "",
                    KutuUcIcerik: "",
                    KutuDortIcerik: "",
                    Deleted: false,
                    RowVersion: 0
                };
                resolve("yeni kayıt formu açıldı...");
            } catch (error) {
                reject(error);
            }
        });

    }

    async kayitDuzenle() {
        return new Promise((resolve, reject) => {
            const satir = this.satir;
            if (satir === null || satir === undefined) {
                reject("Satır seçmelisiniz");
            }
            else {
                try {
                    this.formData = {
                        Id: satir.Id,
                        Baslik: satir.Baslik,
                        Background: satir.Background,
                        KutuBirIkon: satir.KutuBirIkon,
                        KutuBirBaslik: satir.KutuBirBaslik,
                        KutuBirIcerik: satir.KutuBirIcerik,
                        KutuIkiIkon: satir.KutuIkiIkon,
                        KutuIkiBaslik: satir.KutuIkiBaslik,
                        KutuIkiIcerik: satir.KutuIkiIcerik,
                        KutuUcIkon: satir.KutuUcIkon,
                        KutuUcBaslik: satir.KutuUcBaslik,
                        KutuUcIcerik: satir.KutuUcIcerik,
                        KutuDortIkon: satir.KutuDortIkon,
                        KutuDortBaslik: satir.KutuDortBaslik,
                        KutuDortIcerik: satir.KutuDortIcerik,
                        Deleted: satir.Deleted,
                        RowVersion: satir.RowVersion
                    }
                    resolve("kayıt düzenleme formu açıldı...");
                } catch (error) {
                    reject(error);
                }
            }
        });

    }

    async kayitAktifYap() {
        return new Promise((resolve, reject) => {
            const satir = this.satir;
            if (satir === null || satir === undefined) {
                reject("Satır seçmeliniz");
            }
            else {
                service.post(siteApi + 'dortlu/AktifYap/' + satir.Id, null,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + Globals.Token
                        }
                    }
                )
                    .then(res => {
                        if (res.data.Success) {
                            notify("Kaydınız aktif hale getirildi.", 'success', 5000);
                            resolve("kayıt aktif hale getirildi.");
                        }
                        else {
                            notify(res.data.ErrorMessage, 'error', 5000);
                            reject(res.data.ErrorMessage);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            }
        });
    }

    async kayitPasifYap() {
        return new Promise((resolve, reject) => {
            const satir = this.satir;
            if (satir === null || satir === undefined) {
                reject("Satır seçmelisiniz");
            }
            else {
                service.post(siteApi + 'dortlu/PasifYap/' + satir.Id,
                    null,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + Globals.Token
                        }
                    }
                )
                    .then(res => {
                        if (res.data.Success) {
                            notify("Kaydınız pasif hale getirildi.", 'success', 5000);
                            resolve("kayıt pasif hale getirildi");
                        }
                        else {
                            notify(res.data.ErrorMessage, 'error', 5000);
                            reject(res.data.ErrorMessage);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })
            }
        });
    }

    async kayitSil() {
        return new Promise((resolve, reject) => {
            const satir = this.satir;
            if (satir === null || satir === undefined) {
                reject("Satır seçmelisiniz");
            }
            else {
                let result = confirm("<i>Kaydınızı silmek istiyorsunuz, emin misiniz?<br/><font color='red'>Bunun yerine pasif etmeyi seçebilirsiniz</font></i>", "Silme Onay");
                result.then((dialogResult) => {
                    if (dialogResult === true) {
                        service.post(siteApi + "dortlu/sil/" + satir.Id,
                            null, {
                            headers: {
                                'Authorization': 'Bearer ' + Globals.Token
                            }
                        })
                            .then(res => {
                                if (res.data.Success) {
                                    notify("Silme işleminiz gerçekleştirildi.", "success", 5000);
                                    resolve("Silme işleminiz gerçekleştirildi.");
                                }
                                else {
                                    notify(res.data.ErrorMessage, "error", 5000);
                                    reject(res.data.ErrorMessage);
                                }
                            })
                            .catch(error => {
                                reject(error);
                            });
                    }
                });
            }
        });

    }

    async kayitYenile() {
        return new Promise((resolve, reject) => {
            this.setLoadingText("Dörtlü bölmeler getiriliyor...");
            service.get(siteApi + 'dortlu/liste', {
                headers: {
                    'Authorization': 'Bearer ' + Globals.Token
                }
            }).then(res => {
                if (res.data.Success) {
                    this.setState({
                        bolmeler: res.data.Data
                    });
                    this.setLoadingText("");
                    this.satirIptal();
                    resolve("kayıtlar getirildi...");
                }
                else {
                    notify(res.data.ErrorMessage, 'error', 500);
                    this.setLoadingText("");
                    this.satirIptal();
                    reject(res.data.ErrorMessage);
                }
            }).catch(error => {
                this.setLoadingText("");
                this.satirIptal();
                reject(error);
            })
        })
    }

    //#endregion

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-12">
                        {this.state.loadingText}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <h3>Dörtlü Bölmeler</h3>
                        <DataGrid
                            showBorders={true}
                            showRowLines={true}
                            dataSource={this.state.bolmeler}
                            repaintChangesOnly={true}
                            columnHidingEnabled={true}
                            filterPanel={{ visible: true }}
                            filterRow={{ visible: true }}
                            columnAutoWidth={true}
                            selection={{ mode: 'single' }}
                            autoNavigateToFocusedRow={true}
                            focusedRowEnabled={true}
                            hoverStateEnabled={true}
                            rowAlternationEnabled={true}
                            keyExpr="Id"
                            paging={{
                                pageSize: 20
                            }}
                            pager={{
                                showPageSizeSelector: true,
                                allowedPageSizes: [5, 10, 50, 75, 100],
                                showInfo: true
                            }}
                            onSelectionChanged={this.onSelectionChanged}
                            onToolbarPreparing={this.onToolbarPreparing}
                            onInitialized={this.onInitialized}
                            ref={(ref) => this.mainDataGrid = ref}
                        >
                            <Export enabled={true} fileName={'Firmalar'} allowExportSelectedData={false} />
                            <ColumnChooser enabled={true} />
                            <Editing mode={'popup'} />
                            <Grouping contextMenuEnabled={true} expandMode="rowClick" />
                            <GroupPanel visible={true} emptyPanelText="Gruplamak için sütün başlıklarını buraya sürükleyiniz." />
                            <ColumnFixing enabled={true} />
                            <LoadPanel enabled={true} />
                            <FilterRow visible={false} applyFilter={'auto'} />
                            <Paging defaultPageSize={15} />
                            <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={false} />
                            <Column dataField={'Id'} caption="Id" visible={false} />
                            <Column dataField={'Deleted'} caption="Pasif" />
                            <Column dataField={'Baslik'} caption="Başlık" />
                        </DataGrid>
                        <Popup showTitle={true} shading={false} dragEnabled={true} ref={(ref) => this.popupForm = ref}
                            close_ClickOnOutsideClick={false} popupOptions={{ animation: null }} showCloseButton={false}
                            onHiding={this.popupGizle} visible={this.state.popup}
                            toolbarItems={
                                [
                                    {
                                        location: 'after', widget: 'dxButton', options: {
                                            text: 'Kaydet', type: 'success', hint: 'Kaydet', icon: 'check'
                                            , onClick: async (e) => {

                                                await this.kaydet().then(res => {
                                                    console.log(res);
                                                }).catch(error => {
                                                    alert("Kayıt esnasında hata oluştu," + error);
                                                });

                                                await this.kayitYenile().then(res => {
                                                    console.log(res);
                                                }).catch(error => {
                                                    alert("Slayt bilgileri getirilirken hata oluştu," + error);
                                                });

                                                await this.setLoadingText("");

                                                await this.popupGizle();
                                            }
                                        }
                                    },
                                    {
                                        location: 'after', widget: 'dxButton', options: {
                                            text: 'Vazgeç', type: 'danger', hint: 'Çıkış', icon: 'runner'
                                            , onClick: () => { this.popupGizle(); }
                                        }
                                    }

                                ]
                            }
                        >
                            <Position my={'center'} at={'center'} of={window} />
                            <ScrollView id={'scrollview'} showScrollBarMode='onScroll' scrollByContent={true} scrollByThumb={true} bounceEnabled={false}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Form formData={this.formData}>
                                            <GroupItem cssClass="first-group" colCount={1} caption="Başlık">
                                                <SimpleItem dataField={"Baslik"}
                                                    editorOptions={{ placeholder: "Başlık" }}>
                                                    <Label text="Başlık" />
                                                </SimpleItem>
                                                <SimpleItem dataField={"myFile"}
                                                    editorType="dxFileUploader"
                                                    editorOptions={{
                                                        uploadUrl: siteApi + "dortlu/BackgroundUpload",
                                                        uploadMode: 'instantly',
                                                        uploadHeaders: this.headers,
                                                        accept: '*',
                                                        maxFileSize: 4000000,
                                                        name: 'myFile',
                                                        onUploaded: this.onBackgroundUploaded
                                                    }}>
                                                    <Label text="Arka plan seçiniz" />
                                                </SimpleItem>
                                            </GroupItem>
                                            <GroupItem cssClass="first-group" colCount={2} caption="Kutu Bir Bilgileri">
                                                <SimpleItem dataField={"KutuBirBaslik"}
                                                    editorOptions={{ placeholder: "Başlık" }}>
                                                    <Label text="Başlık" />
                                                </SimpleItem>
                                                <SimpleItem dataField={"KutuBirIcerik"}
                                                    editorOptions={{ placeholder: "İçerik" }}>
                                                    <Label text="İçerik" />
                                                </SimpleItem>

                                                <SimpleItem dataField={"myFile1"}
                                                    editorType="dxFileUploader"
                                                    editorOptions={{
                                                        uploadUrl: siteApi + "dortlu/ikon1Upload",
                                                        uploadMode: 'instantly',
                                                        uploadHeaders: this.headers,
                                                        accept: '*',
                                                        maxFileSize: 4000000,
                                                        name: 'myFile1',
                                                        onUploaded: this.onIkon1Uploaded
                                                    }}>
                                                    <Label text="İkon 1 seçiniz" />
                                                </SimpleItem>
                                            </GroupItem>
                                            <GroupItem cssClass="first-group" colCount={2} caption="Kutu İki Bilgileri">
                                                <SimpleItem dataField={"KutuIkiBaslik"}
                                                    editorOptions={{ placeholder: "Başlık" }}>
                                                    <Label text="Başlık" />
                                                </SimpleItem>
                                                <SimpleItem dataField={"KutuIkiIcerik"}
                                                    editorOptions={{ placeholder: "İçerik" }}>
                                                    <Label text="İçerik" />
                                                </SimpleItem>

                                                <SimpleItem dataField={"myFile2"}
                                                    editorType="dxFileUploader"
                                                    editorOptions={{
                                                        uploadUrl: siteApi + "dortlu/ikon2Upload",
                                                        uploadMode: 'instantly',
                                                        uploadHeaders: this.headers,
                                                        accept: '*',
                                                        maxFileSize: 4000000,
                                                        name: 'myFile2',
                                                        onUploaded: this.onIkon2Uploaded
                                                    }}>
                                                    <Label text="İkon 2 seçiniz" />
                                                </SimpleItem>
                                            </GroupItem>
                                            <GroupItem cssClass="first-group" colCount={2} caption="Kutu Üç Bilgileri">
                                                <SimpleItem dataField={"KutuUcBaslik"}
                                                    editorOptions={{ placeholder: "Başlık" }}>
                                                    <Label text="Başlık" />
                                                </SimpleItem>
                                                <SimpleItem dataField={"KutuUcIcerik"}
                                                    editorOptions={{ placeholder: "İçerik" }}>
                                                    <Label text="İçerik" />
                                                </SimpleItem>

                                                <SimpleItem dataField={"myFile3"}
                                                    editorType="dxFileUploader"
                                                    editorOptions={{
                                                        uploadUrl: siteApi + "dortlu/ikon3Upload",
                                                        uploadMode: 'instantly',
                                                        uploadHeaders: this.headers,
                                                        accept: '*',
                                                        maxFileSize: 4000000,
                                                        name: 'myFile3',
                                                        onUploaded: this.onIkon3Uploaded
                                                    }}>
                                                    <Label text="İkon 3 seçiniz" />
                                                </SimpleItem>
                                            </GroupItem>
                                            <GroupItem cssClass="first-group" colCount={2} caption="Kutu Dört Bilgileri">
                                                <SimpleItem dataField={"KutuDortBaslik"}
                                                    editorOptions={{ placeholder: "Başlık" }}>
                                                    <Label text="Başlık" />
                                                </SimpleItem>
                                                <SimpleItem dataField={"KutuDortIcerik"}
                                                    editorOptions={{ placeholder: "İçerik" }}>
                                                    <Label text="İçerik" />
                                                </SimpleItem>

                                                <SimpleItem dataField={"myFile4"}
                                                    editorType="dxFileUploader"
                                                    editorOptions={{
                                                        uploadUrl: siteApi + "dortlu/ikon4Upload",
                                                        uploadMode: 'instantly',
                                                        uploadHeaders: this.headers,
                                                        accept: '*',
                                                        maxFileSize: 4000000,
                                                        name: 'myFile4',
                                                        onUploaded: this.onIkon4Uploaded
                                                    }}>
                                                    <Label text="İkon 3 seçiniz" />
                                                </SimpleItem>
                                            </GroupItem>

                                        </Form>
                                    </div>
                                </div>
                            </ScrollView>
                        </Popup>

                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default withRouter(DortluSectionPage);