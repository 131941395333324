import * as React from 'react';
import { siteApi, kargoTakipUrl } from '../../Utils';
import service from 'axios';
import notify from 'devextreme/ui/notify';
import Logo from '../../components/Header/Logo';
import MenuBtn from '../../components/Header/MenuBtn';
import RightTop from '../../components/Header/RightTop';
import RightBottom from '../../components/Header/RightBottom';
import DetailHeader from '../../components/Header/DetailHeader';
import MainFooter from '../../components/Footer/MainFooter';
import { withRouter } from "react-router";
import { Helmet } from 'react-helmet'
import Moment from 'moment';
class KargoTakip extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Sayfalar: [],
            FacebookAdresi: "",
            TwitterAdresi: "",
            YoutubeAdresi: "",
            LinkedinAdresi: "",
            InstagramAdresi: "",
            Telefon: "",
            TelefonBitisik: "",
            evrakNo: null,
            evrakturu: "",
            musteriTakipNo: null,
            onayTarihi: null,
            hareketler: [],
            WhatsappTel: "",
            kargoTakipNo: this.props.match.params.kargotakipno,
            SayfaIciLink: false,
            gönderenSube: "",
            alici: "",
            aliciAdres: "",
            aliciIlce: "",
            aliciSehir: "",
            aliciUlke: "",
            teslimSube: "",
            teslimSubeTelefon: "",
            sonDurumAciklama: "",
            durum: "none"
        }
        this.getData();
        this.sayac = 1;
    }

    //#region form fonksiyonları

    async getData() {
        await this.getSetting()
            .then(() => {

            })
            .catch(error => {
                console.log(error);
                notify(error, "error", 3000);
            });

        await this.getSitePage()
            .then(() => {

            })
            .catch(error => {
                console.log(error);
                notify(error, "error", 3000);
            });

        await this.getKargoGonderi()
            .then(res => {
                const gonderen = res.gonderen.split(' ');
                const alici = res.alici.split(' ');
                const aliciAdres = res.adres.split(' ');
                this.setState({
                    evrakNo: "#" + res.evrak_no,
                    musteriTakipNo: res.musteri_takip_no,
                    onayTarihi: Moment(new Date(res.onay_tarihi)).format("YYYY-MM-DD"),
                    alici: this.sansurle(alici, 2),
                    aliciAdres: this.sansurle(aliciAdres, 2),
                    gonderen: this.sansurle(gonderen, 2),
                    gonderenSube: res.cikis_sube + " " + res.cikis_sube_telefon,
                    aliciIlce: res.ilce === null ? "" : res.ilce,
                    aliciSehir: res.sehir === null ? "" : res.sehir,
                    aliciUlke: res.ulke === null ? "" : res.ulke,
                    teslimSube: res.teslim_sube === null ? "" : res.teslim_sube,
                    evrakturu: res.evrak_turu,
                    teslimSubeTelefon: res.teslim_sube_telefon === null ? "" : res.teslim_sube_telefon,
                    sonDurumAciklama: res.son_durum_aciklama === null ? "" : res.son_durum_aciklama,
                    hareketler: res.hareketler.filter(x => x.resim_gorunsun === true).sort((a, b) => {
                        return new Date(a.hareket_tarihi) - new Date(b.hareket_tarihi)
                    })
                });
            })
            .catch(error => {
                console.log(error);
                notify(error, "error", 3000);
            });

    }

    adimlar() {

        const sonHareket = this.state.hareketler[this.state.hareketler.length - 1];
        if (sonHareket === null | sonHareket === undefined) {
            return (
                <React.Fragment>
                    <ul id="progressbar" className="text-center">
                        <li className="step0" ></li>
                        <li className="step0"></li>
                        <li className="step0"></li>
                        <li className="step0"></li>
                        <li className="step0"></li>
                    </ul>
                </React.Fragment>
            );
        }
        else if (this.state.evrakturu === "İade" && sonHareket.hareket === 70) {
            return (
                <React.Fragment>
                    <div className="text-center" style={{ fontSize: "24px" }}>
                        <p><strong>Göndericiye iade edilmiştir</strong></p>
                    </div>
                </React.Fragment>
            );
        }
        else if (this.state.evrakturu === "İade" && sonHareket.hareket !== 70) {
            return (
                <React.Fragment>
                    <div className="text-center">
                        <p>Göndericiye iade edilmek üzere yoldadır</p>
                    </div>
                </React.Fragment>
            );
        }
        else if (sonHareket.hareket_aciklama === "Kargo Teslim Edildi" && this.state.evrakturu !== "İade") {
            return (<React.Fragment>
                <ul id="progressbar" className="text-center">
                    <li className="active step0"></li>
                    <li className="active step0"></li>
                    <li className="active step0"></li>
                    <li className="active step0"></li>
                    <li className="active step0"></li>
                </ul>
            </React.Fragment>)
        }

        else if (sonHareket.hareket_aciklama === "Kargo Yola Çıktı") {
            return (<React.Fragment>
                <ul id="progressbar" className="text-center">
                    <li className="active step0"></li>
                    <li className="active step0"></li>
                    <li className="step0"></li>
                    <li className="step0"></li>
                    <li className="step0"></li>
                </ul>
            </React.Fragment>)
        }
        else if (sonHareket.hareket_aciklama === "Kargo Çıkış Şubede") {
            return (<React.Fragment>
                <ul id="progressbar" className="text-center">
                    <li className="active step0"></li>
                    <li className="step0"></li>
                    <li className="step0"></li>
                    <li className="step0"></li>
                    <li className="step0"></li>
                </ul>
            </React.Fragment>)
        }
        else if (sonHareket.hareket_aciklama === "Kargo Varış Şubede") {
            return (<React.Fragment>
                <ul id="progressbar" className="text-center">
                    <li className="active step0"></li>
                    <li className="active step0"></li>
                    <li className="active step0"></li>
                    <li className="step0"></li>
                    <li className="step0"></li>
                </ul>
            </React.Fragment>)
        }
        else if (sonHareket.hareket_aciklama === "Kargo Kuryede") {
            return (<React.Fragment>
                <ul id="progressbar" className="text-center">
                    <li className="active step0"></li>
                    <li className="active step0"></li>
                    <li className="active step0"></li>
                    <li className="active step0"></li>
                    <li className="step0"></li>
                </ul>
            </React.Fragment>)
        }
        else {
            return (
                <React.Fragment>
                    <ul id="progressbar" className="text-center">
                        <li className={this.state.hareketler[0] === undefined ? "step0" : "active step0"}></li>
                        <li className={this.state.hareketler[1] === undefined ? "step0" : "active step0"}></li>
                        <li className={this.state.hareketler[2] === undefined ? "step0" : "active step0"}></li>
                        <li className={this.state.hareketler[3] === undefined ? "step0" : "active step0"}></li>
                        <li className={this.state.hareketler[4] === undefined ? "step0" : "active step0"}></li>
                    </ul>
                </React.Fragment>
            );
        }
    }

    sansurle(deger, sayi) {
        var part1 = "";
        var part2 = "";
        var sonuc = "";
        const sansurBaslangici = sayi;
        for (var index = 0; index < deger.length; index++) {
            var element = deger[index];
            part1 = element.substring(0, sansurBaslangici);
            part2 = element.substring(sansurBaslangici, element.length);
            for (var i = 0; i < part2.length; i++) {

                part2 = part2.replace(part2[i], "*");
            }
            element = part1 + part2;
            sonuc += element + " ";
        }
        return sonuc;
    }
    //#endregion

    //#region servis metodları

    async getSitePage() {
        return new Promise((resolve, reject) => {
            service.get(siteApi + "arayuz/getSiteSayfa", null)
                .then(res => {
                    if (res.data.Success) {
                        this.setState({
                            Sayfalar: res.data.Data
                        });
                        resolve("Sayfa bilgileri getirildi.")
                    }
                    else
                        reject(res.data.ErrorMessage);
                }).catch(error => {
                    reject(error);
                });
        })
    }

    async getSetting() {
        return new Promise((resolve, reject) => {
            service.get(siteApi + "arayuz/getSiteAyar", null)
                .then(res => {
                    if (res.data.Success) {
                        const ayar = res.data.Data[0];
                        this.setState({
                            FacebookAdresi: ayar.facebookadresi,
                            TwitterAdresi: ayar.twitteradresi,
                            YoutubeAdresi: ayar.youtubeadresi,
                            LinkedinAdresi: ayar.linkedinadresi,
                            InstagramAdresi: ayar.instagramadresi,
                            Telefon: ayar.telefon,
                            WhatsappTel: ayar.whatsapptel,
                            TelefonBitisik: ayar.telefon.trim(),
                            Adres: ayar.footeradres,
                            EmailAdresi: ayar.emailadresi,
                            SayfaIciLink: ayar.sayfaicilink
                        });
                        resolve("Ayar bilgisi getirildi...");
                    } else {
                        notify(res.data.ErrorMessage, "error", 3000);
                        reject(res.data.ErrorMessage);
                    }
                })
                .catch(error => {
                    reject(error);
                })
        });
    }

    async getKargoGonderi() {
        return new Promise((resolve, reject) => {

            service.get(kargoTakipUrl + this.state.kargoTakipNo, null)
                .then(res => {
                    if (res.data.success) {
                        if (res.data.value === null) {
                            var newDate = new Date();
                            let dummyData = {
                                evrak_no: "",
                                musteri_takip_no: "",
                                onay_tarihi: Moment(newDate).format("YYYY-MM-DD"),
                                hareketler: []
                            };
                            resolve(dummyData);
                        }
                        else {
                            resolve(res.data.value);
                        }
                    }
                    else {
                        reject(res.data.errorMessage);
                    }
                })
                .catch(error => {
                    reject("Teknik bir hata oluştu.Hata ayrıntısı:" + error);
                })
        })

    }
    //#endregion

    render() {
        return (

            <React.Fragment>
                <Helmet>
                    <title>By Express - Kargo Takip Ekranı</title>
                    <meta name="description" content="By Express Kargo Takip Sorgulama Ekranı"></meta>
                </Helmet>
                <section id="detailPage">
                    <div className="headerContainer">
                        <div className="container d-flex align-items-end justify-content-between">
                            <Logo Logo="/images/logo/logo.png" Baslik="Byepxress"></Logo>
                            <div className="headerRight">
                                <MenuBtn />
                                <RightTop
                                    facebookadresi={this.state.FacebookAdresi}
                                    twitteradresi={this.state.TwitterAdresi}
                                    youtubeadresi={this.state.YoutubeAdresi}
                                    linkedinadresi={this.state.LinkedinAdresi}
                                    instagramadresi={this.state.InstagramAdresi}
                                    telefonbitisik={this.state.TelefonBitisik}
                                    telefon={this.state.Telefon}
                                    SayfaIciLink={this.state.SayfaIciLink} />
                                <RightBottom Sayfalar={this.state.Sayfalar} />
                            </div>
                        </div>
                    </div>

                    {/* detailheader başlangıç  */}
                    <DetailHeader Baslik="Kargo Takip Ekranı" />
                    {/* detailheader bitiş  */}

                    {/* detailcontainer başlangıç  */}
                    <div className="detailContainer">
                        <div className="container kargotakibi-page">
                            <div className="card">
                                <div className="d-flex justify-content-between px-3 top">
                                    <div className="d-flex  flex-column text-sm-left">
                                        <h5>Kargo Evrak No <span className="text-primary font-weight-bold" id="evrakNo">{this.state.evrakNo}</span></h5>
                                        <p>Kargo Takip no <span className="font-weight-bold" id="kargoTakipNo">{this.state.kargoTakipNo}</span></p>
                                    </div>
                                    <div className="d-flex flex-column text-sm-right">
                                        <p className="mb-0">Tarih <span id="onayTarihi">{this.state.onayTarihi}</span></p>
                                        <p>Takip no <span className="font-weight-bold" id="musteriTakipNo">{this.state.musteriTakipNo}</span></p>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-center">
                                    <div className="col-12">

                                        {this.adimlar()}
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between top icon-container" style={{ display: this.state.durum }}>
                                    <div className="d-flex icon-content"> <img className="icon" src="/images/icon/001.svg" alt="Kargo Hazırlanıyor" />
                                        <div className="d-flex flex-column">
                                            <p className="font-weight-bold">Çıkış<br />Şubesinde</p>
                                        </div>
                                    </div>
                                    <div className="d-flex icon-content"> <img className="icon" src="/images/icon/002.svg" alt="Yola Çıktı" />
                                        <div className="d-flex flex-column">
                                            <p className="font-weight-bold">Yola<br />Çıktı</p>
                                        </div>
                                    </div>
                                    <div className="d-flex icon-content mobile-active"> <img className="icon" src="/images/icon/003.svg" alt="Varış Şubesinde" />
                                        <div className="d-flex flex-column">
                                            <p className="font-weight-bold">Varış<br />Şubesinde</p>
                                        </div>
                                    </div>
                                    <div className="d-flex icon-content"> <img className="icon" src="/images/icon/004.svg" alt="Dağıtıma Çıkarıldı" />
                                        <div className="d-flex flex-column">
                                            <p className="font-weight-bold">Dağıtıma<br />Çıkarıldı</p>
                                        </div>
                                    </div>
                                    <div className="d-flex icon-content"> <img className="icon" src="/images/icon/bayrak.svg" alt="Teslim Edildi" />
                                        <div className="d-flex flex-column">
                                            <p className="font-weight-bold">Teslim<br />Edildi</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    {/* detailcontainer bitiş  */}

                    {/* detailcontainer başlangıç  */}
                    <div className="detailContainer">
                        <div className="container kargotakibi-page">
                            <div className="card">
                                <div className="row d-flex justify-content-between px-3 top">
                                    <div className="d-flex">
                                        <h6>
                                            <span className="text-primary font-weight-bold">Gönderen </span>{this.state.gonderen}
                                            {this.state.gonderenSube}
                                        </h6>
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-between px-3 top">
                                    <div className="d-flex">
                                        <h6>
                                            <span className="text-primary font-weight-bold">Gönderen Şube </span>
                                            {this.state.gonderenSube}
                                        </h6>
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-between px-3 top">
                                    <div className="d-flex">
                                        <h6>
                                            <span className="text-primary font-weight-bold">Alıcı </span>{this.state.alici}
                                        </h6>
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-between px-3 top">
                                    <div className="d-flex">
                                        <h6>
                                            <span className="text-primary font-weight-bold">Adres </span>{this.state.aliciAdres}
                                        </h6>
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-between px-3 top">
                                    <div className="d-flex">
                                        <h6>
                                            <span className="text-primary font-weight-bold">İlçe/İl/Ülke </span>{this.state.aliciIlce}/{this.state.aliciSehir}/{this.state.aliciUlke}
                                        </h6>
                                    </div>
                                </div>

                                <div className="row d-flex justify-content-between px-3 top">
                                    <div className="d-flex">
                                        <h6>
                                            <span className="text-primary font-weight-bold">
                                                Teslim Şube </span>{this.state.teslimSube}
                                        </h6>
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-between px-3 top">
                                    <div className="d-flex">
                                        <h6>
                                            <span className="text-primary font-weight-bold">
                                                <a target="_blank"
                                                    href={"https://api.whatsapp.com/send?phone=+90" + this.state.teslimSubeTelefon + "&amp;text=merhaba&amp;source=www.flypgs.com&amp;data="}

                                                    rel="noreferrer"
                                                >
                                                    <img src="/images/icon/whatsapp.png" alt="Whatsapp" style={{
                                                        width: "32px", height: "32px"
                                                    }} />  Şube ile whatsapp üzerinden iletişime geçmek için tıklayınız
                                                </a>
                                            </span>
                                        </h6>
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-between px-3 top">
                                    <div className="d-flex">
                                        <h6>
                                            <span className="text-primary font-weight-bold">
                                                Telefon </span> {this.state.teslimSubeTelefon}

                                        </h6>
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-between px-3 top">
                                    <div className="d-flex">
                                        <h6>
                                            <span className="text-primary font-weight-bold">
                                                Durum </span>{this.state.evrakturu === "İade" ? "İade teslim edildi " : this.state.sonDurumAciklama}

                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* detailcontainer bitiş  */}

                    {/* detailcontainer başlangıç  */}
                    <div className="detailContainer">
                        <div className="container kargotakibi-page">
                            <div className="card">
                                <div className="d-flex justify-content-between px-3 top">
                                    <table className="table" style={{ width: "100%" }}>
                                        <thead>
                                            <tr>
                                                <th>
                                                    Tarih
                                            </th>
                                                <th>
                                                    Açıklama
                                            </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.hareketler.filter(x => x.resim_gorunsun === true).map((hareket, index) => {
                                                return <tr key={index}>
                                                    <td>{Moment(new Date(hareket.hareket_tarihi)).format("YYYY-MM-DD HH:mm:ss")}</td>
                                                    <td>{hareket.hareket_aciklama}</td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* detailcontainer bitiş  */}



                    {/* footer başlangıç  */}
                    <MainFooter
                        Menuler={this.state.Sayfalar}
                        FacebookAdresi={this.state.FacebookAdresi}
                        TwitterAdresi={this.state.TwitterAdresi}
                        YoutubeAdresi={this.state.YoutubeAdresi}
                        LinkedinAdresi={this.state.LinkedinAdresi}
                        InstagramAdresi={this.state.InstagramAdresi}
                        SayfaIciLink={this.state.SayfaIciLink} />
                    {/* footer bitiş  */}
                </section>
            </React.Fragment>
        );
    }
}
export default withRouter(KargoTakip)