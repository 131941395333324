
import * as React from "react";
import notify from 'devextreme/ui/notify';
import { Drawer, Toolbar } from 'devextreme-react';
import auth from '../Auth';
import LeftMenu from '../components/shared/LeftMenu';
import Globals from '../Globals';
import { authApi } from '../Utils';
import service from 'axios';
import trMessages from "devextreme/localization/messages/tr.json";
import { locale, loadMessages } from "devextreme/localization";



export default class AuthorizedLayout extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            onOpen: true,
            vposition: "left",
            //seciliTabId: 0,
            actionType: 0
        };
        this.toolbarItems = [
            { widget: 'dxButton', location: 'before', options: { icon: 'menu', onClick: () => (this.setState({ onOpen: !this.state.onOpen })) } },
            { widget: 'dxButton', location: 'before', options: { icon: 'codeblock', onClick: () => (this.setState({ vposition: this.state.vposition === "left" ? "right" : "left" })) } },
            { location: 'center', locateInMenu: 'never', template: () => { return '<div class=\'toolbar-label\'><b>Byexpress</b> Kargo Yönetim</div>'; } },
            // {
            //     location: 'after', widget: 'dxSelectBox', locateInMenu: 'auto',
            //     options: {
            //         width: 220, items: [
            //             { 'id': 'ios7.default', 'text': 'Apple iOS7' },

            //             { 'id': 'generic.light.compact', 'text': 'Gündüz Küçük' },
            //             { 'id': 'generic.dark.compact', 'text': 'Gece Küçük' },
            //             { 'id': 'generic.carmine.compact', 'text': 'Turuncu Küçük' },
            //             { 'id': 'generic.softblue.compact', 'text': 'Bulanık Mavi Küçük' },
            //             { 'id': 'generic.darkmoon.compact', 'text': 'Gece Cam Göbeği Küçük' },
            //             { 'id': 'generic.darkviolet.compact', 'text': 'Gece Çilek Küçük' },
            //             { 'id': 'generic.greenmist.compact', 'text': 'Gündüz Cam Göbeği Küçük' },
            //             { 'id': 'generic.contrast.compact', 'text': 'Çilek ve Siyah Küçük' },

            //             { 'id': 'generic.light', 'text': 'Gündüz' },
            //             { 'id': 'generic.dark', 'text': 'Gece' },
            //             { 'id': 'generic.carmine', 'text': 'Turuncu' },
            //             { 'id': 'generic.softblue', 'text': 'Bulanık Mavi' },
            //             { 'id': 'generic.darkmoon', 'text': 'Gece Cam Göbeği' },
            //             { 'id': 'generic.darkviolet', 'text': 'Gece Çilek' },
            //             { 'id': 'generic.greenmist', 'text': 'Gündüz Cam Göbeği' },
            //             { 'id': 'generic.contrast', 'text': 'Çilek ve Siyah' },
            //             { 'id': 'material.blue.light.compact', 'text': 'Mt Gündüz Mavi Küçük' },
            //             { 'id': 'material.blue.dark.compact', 'text': 'Mt Gece Mavi Küçük' }
            //         ],
            //         valueExpr: 'id', displayExpr: 'text',
            //         onValueChanged: (args) => {
            //             localStorage.setItem("tema", args.value);
            //             window.location.href = "/";
            //         }
            //     }
            // },
            {
                location: 'after',
                widget: 'dxDropDownButton',
                options: {
                    displayExpr: 'name',
                    keyExpr: 'id',
                    selectedItemKey: 1,
                    stylingMode: 'text',
                    useSelectMode: false,
                    splitButton: true,
                    text: Globals.AdSoyad,
                    items: [
                        { 'name': 'Oturumu Kapat', 'id': 1 },
                        { 'name': 'Profilim', 'id': 2 }],


                    onButtonClick: (e) => {
                        this.onClickSignOut(e);
                    },
                    onItemClick: (e) => {
                        if (e.itemData.id === 1)
                            this.onClickSignOut(e);
                        else
                            if (e.itemData.id === 2)
                                this.onClickProfile(e);

                    }
                }
            }
        ];
        loadMessages(trMessages);
        locale(navigator.language);
    }

    bind
    async onClickSignOut(e) {
        notify(`${e.component.option('text')} ile portaldan çıkıyorsunuz.`, 'success', 500);
        Globals.serviceUser = null;
        service.post(authApi + "account/logout", null, {
            headers: {
                'Authorization': 'Bearer ' + Globals.Token
            }
        })
            .then(res => {

                auth.logout(() => {
                    Globals.Token = null;
                    console.log(this.props.children.props.history.push("/login"));
                });
            })
            .catch((error) => {
                // Error 😨
                if (error.response) {
                    notify(error.response.data.message, 'error', 5000);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }

            });
    }

    bind
    async onClickProfile(e) {
        notify(`${e.component.option('text')} ile portalınızı kişiselleştirebilirsiniz.`, 'success', 500);
        this.setState({ actionType: 2 });
    }

    bind

    render() {
        return (
            <React.Fragment>
                <div className={'dx-theme-background-color'}>
                    <div className="container" style={{ minWidth: "100%" }}>
                        <div className="row">
                            <div className="col-md-12">
                                <Toolbar items={this.toolbarItems} />
                            </div>
                        </div>
                        <div className="row" style={{ minHeight: "10px" }}></div>
                        <div className="row">
                            <div className="col-md-12">
                                <Drawer
                                    opened={this.state.onOpen}
                                    openedStateMode={'shrink'}
                                    position={this.state.vposition}
                                    revealMode={'slide'}
                                    component={LeftMenu}
                                    height={"auto"}
                                    closeOnOutsideClick={true}>
                                    <div>
                                        {this.props.children}
                                    </div>
                                </Drawer>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
