import * as React from 'react';
import { siteApi } from '../../Utils'
import service from 'axios';
import notify from 'devextreme/ui/notify';
export default class SubeFiltreEkrani extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ulkeler: [],
            iller: [],
            ilceler: [],
            filtrelenmisSubeler: []
        }

        // this.getUlkeler()
        //     .then(res => { })
        //     .catch(error => {
        //         notify(error, "error", 3000);
        //     })
        this.getIller(1)
            .then(res => {

            })
            .catch(error => {
                notify(error, "error", 3000);
            })
    }

    //#region  servis metodları
    async getUlkeler() {
        return new Promise((resolve, reject) => {
            service.get(siteApi + "arayuz/getUlkeler", null)
                .then(res => {
                    if (res.data.Success) {
                        this.setState({
                            ulkeler: res.data.Data
                        });
                        resolve("ülke bilgileri getirildi.");
                    }
                    else {
                        reject(res.data.ErrorMessage);
                    }
                })
                .catch(error => {
                    reject(error);
                })
        })

    }
    intervalTemizle(interval) {
        window.clearInterval(interval);
    }
    async getIller(ulkeId) {
        return new Promise((resolve, reject) => {
            service.get(siteApi + "arayuz/getIller/" + ulkeId, null)
                .then(res => {
                    if (res.data.Success) {
                        var subeIller = [];

                        var subeInterval = window.setInterval(() => {
                            console.log("interval çalıştı");
                            if (this.props.Subeler.length > 0) {
                                if (res.data.Data.length > 0) {
                                    res.data.Data.forEach(x => {
                                        if (this.props.Subeler.filter(y => y.il_id === x.id).length > 0) {
                                            subeIller.push(x);
                                        }
                                    });
                                    this.setState({
                                        iller: subeIller
                                    });
                                    this.intervalTemizle(subeInterval);
                                }
                            }
                        }, 100);


                        resolve("il bilgileri getirildi.");
                    }
                    else {
                        reject(res.data.ErrorMessage);
                    }
                })
                .catch(error => {
                    reject(error);
                })
        })

    }
    async getIlceler(ilceid) {
        return new Promise((resolve, reject) => {
            service.get(siteApi + "arayuz/getIlceler/" + ilceid, null)
                .then(res => {
                    if (res.data.Success) {
                        var subeIlceler = [];
                        if (res.data.Data.length > 0) {

                            res.data.Data.forEach(x => {
                                if (this.props.Subeler.filter(y => y.ilce_id === x.id).length > 0) {
                                    subeIlceler.push(x);
                                }
                            });
                            this.setState({
                                ilceler: subeIlceler
                            });
                        }

                        resolve("ilçe bilgileri getirildi.");
                    }
                    else {
                        reject(res.data.ErrorMessage);
                    }
                })
                .catch(error => {
                    reject(error);
                })
        })

    }
    //#endregion
    render() {
        return (
            <React.Fragment>
                <div className="container sube-page">
                    <div className="subeForm mB30">
                        <div className="formContainer d-flex">
                            <div className="formItem col-3" style={{ display: "none" }}>
                                {/* <select name="" id="" className="inputDropdown" onChange={async (e) => {
                                    await this.getIller(e.target.value)
                                        .then(res => {

                                        })
                                        .catch(() => {
                                            this.setState(
                                                {
                                                    iller: [],
                                                    ilceler: [],
                                                    filtrelenmisSubeler: []
                                                });
                                        })
                                }}>
                                    <option value="">Ülke Seçiniz</option>
                                    {this.state.ulkeler.map((ulke, i) => {
                                        return <option key={i} value={ulke.id}>{ulke.adi}</option>
                                    })}
                                </select> */}
                            </div>
                            <div className="formItem col-3 mL20">
                                <select name="" id="" className="inputDropdown" onChange={async (e) => {
                                    await this.getIlceler(e.target.value)
                                        .then(res => {
                                            this.setState(
                                                {
                                                    filtrelenmisSubeler: []
                                                });
                                        })
                                        .catch(() => {
                                            this.setState(
                                                {
                                                    ilceler: [],
                                                    filtrelenmisSubeler: []
                                                });
                                        })
                                }}>
                                    <option value="">İl Seçiniz</option>
                                    {this.state.iller.map((il, i) => {
                                        return <option key={i} value={il.id}>{il.adi}</option>
                                    })}
                                </select>
                            </div>
                            <div className="formItem col-3 mL20">
                                <select name="" id="" value={this.state.selectedIlce} className="inputDropdown"
                                    onChange={(e) => {
                                        this.setState({
                                            selectedIlce: e.target.value,
                                            filtrelenmisSubeler: this.props.Subeler.filter(x => x.ilce_id === parseInt(e.target.value))
                                        });
                                    }}
                                >
                                    <option value={0}>İlçe Seçiniz</option>
                                    {this.state.ilceler.map((ilce, i) => {
                                        return <option key={i} value={ilce.id}>{ilce.adi}</option>;
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="subelerList">
                        {this.state.filtrelenmisSubeler.map((sube, i) => {

                            return <div className="listItem d-flex mB20" key={i}>
                                <div className="col-4">
                                    <h2>{sube.adi}</h2>
                                    <p><strong>Adres</strong></p>
                                    <p>{sube.adres}</p>
                                    <p><strong>Telefon</strong></p>
                                    <p><a href={"tel:" + sube.telefon}>{sube.telefon}</a></p>
                                    <p>
                                        <a target="_blank"
                                            href={"https://api.whatsapp.com/send?phone=+90" + sube.telefon + "&amp;text=merhaba&amp;source=www.flypgs.com&amp;data="}
                                            rel="noreferrer"
                                        >
                                            <img src="/images/icon/whatsapp.png" alt="Whatsapp" style={{
                                                width: "32px", height: "32px"
                                            }} />
                                            <span>Acente Whatsapp İletişim Hattı</span>
                                        </a>
                                    </p>
                                </div>
                                <div className="col-8">
                                    <iframe
                                        title={sube.adi}
                                        src={(sube.koordinat === null | sube.koordinat === "" | sube.koordinat === undefined) ? "" : sube.koordinat}
                                        style={{
                                            width: "100%", height: "360px", frameborder: "0", border: "0"
                                        }}
                                        allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
                                </div>
                            </div>
                        })}
                    </div>
                </div>


            </React.Fragment>
        );
    }
}