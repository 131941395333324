import React from 'react';
import { siteApi } from '../../Utils';
import Globals from '../../Globals';
import service from 'axios';
import notify from 'devextreme/ui/notify';
import DataGrid, {
    Export, ColumnChooser, ColumnFixing, Column, Editing, Paging, Pager, FilterRow, LoadPanel, Grouping, GroupPanel
} from 'devextreme-react/data-grid';
import { withRouter } from "react-router";
class BayiBasvurulariPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingText: "",
            formlar: [],
            toolbarDisabled: false
        }
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.kayitYenile();
    }
    //#region  form fonksiyonları
    setLoadingText(mesaj) {
        this.setState({
            loadingText: mesaj
        });
    }
    //#endregion

    //#region grid fonksiyonları

    onSelectionChanged(selectedItems) {
        this.satir = selectedItems.selectedRowsData[0];
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', hint: 'Yenile', icon: 'refresh',
                    disabled: this.state.toolbarDisabled,
                    onClick: () => {
                        this.kayitYenile().then(res => {
                            console.log(res);

                        }).catch(error => {
                            alert(error);
                        });
                    }
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', icon: 'runner', hint: 'Ana Sayfa',
                    disabled: this.state.toolbarDisabled,
                    onClick: () => {
                        alert("not implement");
                    }
                }
            }
        );
    }

    //#endregion


    //#region  servis metodları
    async kayitYenile() {
        return new Promise((resolve, reject) => {
            this.setLoadingText("formlar getiriliyor...");
            service.get(siteApi + 'ortak/getBayiBasvurulari', {
                headers: {
                    'Authorization': 'Bearer ' + Globals.Token
                }
            }).then(res => {
                if (res.data.Success) {
                    this.setState({
                        formlar: res.data.Data
                    });
                    this.setLoadingText("");
                    resolve("kayıtlar getirildi...");
                }
                else {
                    notify(res.data.ErrorMessage, 'error', 500);
                    this.setLoadingText("");
                    reject(res.data.ErrorMessage);
                }
            }).catch(error => {
                this.setLoadingText("");
                reject(error);
            })
        })
    }
    //#endregion

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-12">
                        {this.state.loadingText}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <h3>Bayi Başvuru Formları</h3>
                        <DataGrid
                            showBorders={true}
                            showRowLines={true}
                            dataSource={this.state.formlar}
                            repaintChangesOnly={true}
                            columnHidingEnabled={true}
                            filterPanel={{ visible: true }}
                            filterRow={{ visible: true }}
                            columnAutoWidth={true}
                            selection={{ mode: 'single' }}
                            autoNavigateToFocusedRow={true}
                            focusedRowEnabled={true}
                            hoverStateEnabled={true}
                            rowAlternationEnabled={true}
                            keyExpr="id"
                            paging={{
                                pageSize: 20
                            }}
                            pager={{
                                showPageSizeSelector: true,
                                allowedPageSizes: [5, 10, 50, 75, 100],
                                showInfo: true
                            }}
                            onToolbarPreparing={this.onToolbarPreparing}
                            onInitialized={this.onInitialized}
                            ref={(ref) => this.mainDataGrid = ref}
                        >
                            <Export enabled={true} fileName={'Formlar'} allowExportSelectedData={false} />
                            <ColumnChooser enabled={true} />
                            <Editing mode={'popup'} />
                            <Grouping contextMenuEnabled={true} expandMode="rowClick" />
                            <GroupPanel visible={true} emptyPanelText="Gruplamak için sütün başlıklarını buraya sürükleyiniz." />
                            <ColumnFixing enabled={true} />
                            <LoadPanel enabled={true} />
                            <FilterRow visible={false} applyFilter={'auto'} />
                            <Paging defaultPageSize={15} />
                            <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={false} />
                            <Column dataField={'id'} caption="Id" visible={false} />
                            <Column dataField={'ad'} caption="Ad" />
                            <Column dataField={'soyad'} caption="Soyad" />
                            <Column dataField={'ulke_adi'} caption="Ülke" />
                            <Column dataField={'il_adi'} caption="İl" />
                            <Column dataField={'ilce_adi'} caption="İlçe" />
                            <Column dataField={'gsm'} caption="Gsm" />
                            <Column dataField={'telefon'} caption="Telefon" />
                            <Column dataField={'email'} caption="Email" />
                            <Column dataField={'created'} caption="Tarih" dataType={"date"} />
                        </DataGrid>

                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default withRouter(BayiBasvurulariPage)