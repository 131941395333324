import React from 'react';
import service from 'axios';
import Globals from '../Globals';
import auth from "../Auth";
import { authApi } from '../Utils';
import Form, {
  ButtonItem,
  GroupItem,
  SimpleItem,
  Label,
  RequiredRule
} from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import { alert } from "devextreme/ui/dialog";
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.carmine.css';
import { withRouter } from "react-router";
class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // const tema = localStorage.getItem("tema");//kullanıcı teması
    // if (tema != null)
    //   themes.current(tema);


    this.handleSubmit = this.handleSubmit.bind(this);
    this.formData = {
      Email: "",
      Password: ""
    };

    this.buttonOptions = {
      text: 'Giriş',
      type: 'success',
      onClick: this.handleSubmit,
      useSubmitBehavior: true
    };

    this.passwordOptions = {
      mode: 'password'
    };


  }

  async handleSubmit(e) {
    return await new Promise((resolve, reject) => {
      service.post(authApi + "Auth/authenticate"
        , this.formData)
        .then(res => {
          if (res.data.Success) {

            resolve(res.data);

          }
          else {
            notify(res.data.ErrorMessage, 'error', 5000);
            reject(res.data.ErrorMessage);
          }
        })
        .catch((error) => {
          reject("Teknik bir hata oluştu, hata ayrıntısı:" + error);
        });
    }).then(res => {
      auth.login(() => {
        Globals.AdSoyad = res.Data.Kullanici.AdSoyad;
        Globals.menuler = res.Data.Kullanici.Menuler;
        Globals.serviceUser = res.Data.Kullanici;
        Globals.Token = res.Data.Token;
        this.props.history.push("/yonetimselaraclar");
      });
    })
      .catch(error => {
        alert(error);
      });

  };
  render() {
    return (
      <div id="container">

        <Form
          formData={this.formData}
          readOnly={false}
          showColonAfterLabel={true}
          showValidationSummary={true}
        >
          <GroupItem caption="Yönetici Girişi">
            <SimpleItem dataField="Email" editorType="dxTextBox">
              <Label text="Email" />
              <RequiredRule message="Email alanı zorunludur" />
            </SimpleItem>
            <SimpleItem dataField="Password" editorType="dxTextBox" editorOptions={this.passwordOptions}>
              <Label text="Şifre" />
              <RequiredRule message="Şifre alanı zorunludur" />
            </SimpleItem>
          </GroupItem>
          <ButtonItem horizontalAlignment="left"
            buttonOptions={this.buttonOptions}
          />
        </Form>
        <br /><br />


      </div>
    );
  }
}
export default withRouter(LoginPage);