import React from 'react';
import { siteApi } from '../../Utils';
import Globals from '../../Globals';
import service from 'axios';
import notify from 'devextreme/ui/notify';
import DataGrid, {
    Export, ColumnChooser, ColumnFixing, Column, Editing, Paging, Pager, FilterRow, LoadPanel, Grouping, GroupPanel
} from 'devextreme-react/data-grid';
import { Popup } from 'devextreme-react';
import ScrollView from 'devextreme-react/scroll-view';
import { Position } from 'devextreme-react/data-grid';
import Form, { SimpleItem, GroupItem, Label } from 'devextreme-react/form';
import { withRouter } from "react-router";
import { confirm, alert } from 'devextreme/ui/dialog';
class SubelerPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            subeler: [],
            ulkeler: [],
            iller: [],
            ilceler: [],
            secilenUlke: null,
            secilenIl: null,
            secilenIlce: null,
            popup: false,
            loadingText: ""

        };
        this.satir = null;
        this.mainDataGrid = null;
        this.onInitialized = this.onInitialized.bind(this);
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.kayitYeni = this.kayitYeni.bind(this);
        this.kayitDuzenle = this.kayitDuzenle.bind(this);
        this.kayitAktifYap = this.kayitAktifYap.bind(this);
        this.kayitPasifYap = this.kayitPasifYap.bind(this);
        this.kayitSil = this.kayitSil.bind(this);
        this.kayitYenile = this.kayitYenile.bind(this);
        this.onInitialized = this.onInitialized.bind(this);
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.formData = {
            Id: 0,
            Kodu: "",
            Adi: "",
            Adres: "",
            Ulke: { Id: 0 },
            Il: { Id: 0 },
            Ilce: { Id: 0 },
            Telefon: "",
            PaketTelefonu: "",
            CepTelefonu: "",
            Email: "",
            YetkiliBir: "",
            YetkiliIki: "",
            Koordinat: "",
            Deleted: false,
            RowVersion: 0
        };

        this.kayitYenile().then(res => {
            console.log(res);

        }).catch(error => {
            alert(error);
        });
    }

    //#region form fonksiyonları

    popupGizle() {
        this.setState(
            {
                popup: false,
                toolbarDisabled: false
            });
        this.satirIptal();
    };

    popupAc() {
        this.setState(
            {
                popup: true,
                toolbarDisabled: true
            });
    };

    setLoadingText(mesaj) {
        this.setState({
            loadingText: mesaj
        });
    }

    //#endregion

    //#region  servis fonksiyonları

    async getUlkeler(satirId) {
        return new Promise((resolve, reject) => {
            service.get(siteApi + 'ortak/getUlkeler', {
                headers: {
                    'Authorization': 'Bearer ' + Globals.Token
                }
            }).then(res => {
                if (res.data.Success) {
                    this.setState({
                        ulkeler: res.data.Data,
                        secilenUlke: satirId
                    });
                    resolve("Ülke bilgileri eklendi");
                }
                else {
                    reject(res.data.ErrorMessage);
                }
            }).catch(() => {

                reject("Teknik bir hata oluştu,ülke bilgileri getirilemedi");
            });
        })
    }

    async getIller(ilId, ulkeId) {
        return new Promise((resolve, reject) => {
            service.get(siteApi + 'ortak/getIller/' + ulkeId, {
                headers: {
                    'Authorization': 'Bearer ' + Globals.Token
                }
            }).then(res => {
                if (res.data.Success) {
                    this.setState({
                        iller: res.data.Data,
                        secilenIl: ilId
                    });
                    resolve("İl bilgileri yüklendi");
                }
                else {
                    reject(res.data.ErrorMessage);
                }
            }).catch(() => {

                reject("Teknik bir hata oluştu,İl bilgileri getirilemedi");
            });
        })
    }

    async getIlceler(ilceId, ilId) {
        return new Promise((resolve, reject) => {
            service.get(siteApi + 'ortak/getIlceler/' + ilId, {
                headers: {
                    'Authorization': 'Bearer ' + Globals.Token
                }
            }).then(res => {
                if (res.data.Success) {
                    this.setState({
                        ilceler: res.data.Data,
                        secilenIlce: ilceId
                    });
                    resolve("İlçe bilgileri yüklendi");
                }
                else {
                    reject(res.data.ErrorMessage);
                }
            }).catch(() => {

                reject("Teknik bir hata oluştu,İlçe bilgileri getirilemedi");
            });
        })
    }


    async kaydet() {
        return new Promise((resolve, reject) => {
            service.post(siteApi + "websube/subeKaydet",
                this.formData,
                {
                    headers: {
                        'Authorization': 'Bearer ' + Globals.Token
                    }
                })
                .then(res => {
                    if (res.data.Success) {
                        notify("Şube bilgisi kaydedildi", "success", 2000);
                        resolve("Şube bilgisi kaydedildi...");
                    }
                    else {
                        notify(res.data.ErrorMessage, "error", 2000);
                        reject(res.data.ErrorMessage);
                    }
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    //#endregion

    //#region grid fonksiyonları

    onSelectionChanged(selectedItems) {
        this.satir = selectedItems.selectedRowsData[0];
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', hint: 'Yeni', icon: 'add',
                    disabled: this.state.toolbarDisabled,
                    onClick: (async () => {
                        let isError = false;
                        await this.kayitYeni().then(res => {
                            console.log(res);
                        })
                            .catch(error => {
                                alert(error, "Uyarı");
                                isError = true;
                            });
                        if (this.state.ulkeler.length === 0) {
                            await this.getUlkeler(null).then(res => {
                                console.log(res);
                            })
                                .catch(error => {
                                    alert(error, "Uyarı");
                                    isError = true;
                                });
                        }
                        if (!isError)
                            await this.popupAc();
                        else
                            this.satirIptal();
                    })
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', hint: 'Düzenle', icon: 'edit',
                    disabled: this.state.toolbarDisabled,
                    onClick: (async () => {
                        let isError = false;
                        await this.kayitDuzenle().then(res => { console.log(res) })
                            .catch(error => {
                                alert(error, "Uyarı");
                                isError = true;
                            });
                        await this.getUlkeler(null).then(() => { }).catch(error => {
                            alert(error, "Uyarı");
                            isError = true;
                        });
                        if (!isError)
                            await this.popupAc();
                        else
                            this.satirIptal();

                    })
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'danger', hint: 'Sil', icon: 'remove',
                    disabled: this.state.toolbarDisabled,
                    onClick: (async () => {
                        let isError = false;
                        await this.kayitSil()
                            .then(res => { console.log(res) })
                            .catch(error => {
                                alert(error, "Uyarı");
                                isError = true;
                            });

                        if (!isError) {
                            await this.kayitYenile()
                                .then(res => {
                                    console.log(res)
                                })
                                .catch(error => {
                                    alert(error, "Uyarı")
                                });
                        }
                        else
                            this.satirIptal();
                    })
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'danger', hint: 'Pasif yap', icon: 'isblank',
                    disabled: this.state.toolbarDisabled,
                    onClick: (async () => {
                        let isError = false;
                        await this.kayitPasifYap()
                            .then(res => { console.log(res) })
                            .catch(error => {
                                alert(error, "Uyarı");
                                isError = true;
                            });

                        if (!isError) {
                            await this.kayitYenile()
                                .then(res => {
                                    console.log(res)
                                })
                                .catch(error => {
                                    alert(error, "Uyarı")
                                });
                        }
                        else
                            this.satirIptal();
                    })
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'success', hint: 'Aktif yap', icon: 'isnotblank',
                    disabled: this.state.toolbarDisabled,
                    onClick: (async () => {
                        let isError = false;

                        await this.kayitAktifYap()
                            .then(res => { console.log(res) })
                            .catch(error => {
                                alert(error, "Uyarı");
                                isError = true;
                            });

                        if (!isError) {
                            await this.kayitYenile()
                                .then(res => {
                                    console.log(res)
                                })
                                .catch(error => {
                                    alert(error, "Uyarı")
                                });
                        }
                        else
                            this.satirIptal();
                    })
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', hint: 'Yenile', icon: 'refresh',
                    disabled: this.state.toolbarDisabled,
                    onClick: () => {
                        this.kayitYenile().then(res => {
                            console.log(res);

                        }).catch(error => {
                            alert(error);
                        });
                    }
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', icon: 'runner', hint: 'Ana Sayfa',
                    onClick: () => {
                        alert("not implement");
                    }
                }
            }
        );
    }

    onInitialized(e) {
        this.mainDataGrid = e.component;
    }

    async satirIptal() {
        if (this.mainDataGrid.instance !== null) {
            this.mainDataGrid.instance.clearSelection();
            this.mainDataGrid.instance.option("focusedRowIndex", -1);
        }
    }

    //#endregion

    //#region toolbar fonksiyonları

    async kayitYeni() {
        return new Promise((resolve, reject) => {
            try {
                this.formData = {
                    Id: 0,
                    Kodu: "",
                    Adi: "",
                    Adres: "",
                    Ulke: { Id: 0 },
                    Il: { Id: 0 },
                    Ilce: { Id: 0 },
                    Telefon: "",
                    PaketTelefonu: "",
                    CepTelefonu: "",
                    Email: "",
                    YetkiliBir: "",
                    YetkiliIki: "",
                    Koordinat: "",
                    Deleted: false,
                    RowVersion: 0
                };
                this.setState({
                    secilenIl: null,
                    secilenIlce: null,
                    secilenUlke: null
                });
                resolve("yeni kayıt formu açıldı...");
            } catch (error) {
                reject(error);
            }
        });

    }

    async kayitDuzenle() {
        const satir = this.satir;
        return new Promise((resolve, reject) => {

            if (satir === null || satir === undefined) {
                reject("Satır seçmelisiniz");
            }
            else {
                try {
                    this.setState({
                        secilenUlke: satir.Ulke.Id,
                        secilenIl: satir.Il.Id,
                        secilenIlce: satir.Ilce.Id
                    });

                    this.formData = {
                        Id: satir.Id,
                        Kodu: satir.Kodu,
                        Adi: satir.Adi,
                        Koordinat: satir.Koordinat,
                        Adres: satir.Adres,
                        Telefon: satir.Telefon,
                        PaketTelefonu: satir.PaketTelefonu,
                        CepTelefonu: satir.CepTelefonu,
                        Email: satir.Email,
                        YetkiliBir: satir.YetkiliBir,
                        YetkiliIki: satir.YetkiliIki,
                        Ulke: { Id: satir.Ulke.Id },
                        Il: { Id: satir.Il.Id },
                        Ilce: { Id: satir.Ilce.Id },
                        RowVersion: satir.RowVersion
                    }

                    resolve("kayıt düzenleme formu açıldı...");
                } catch (error) {
                    reject(error);
                }
            }
        });

    }

    async kayitAktifYap() {
        const satir = this.satir;
        return new Promise((resolve, reject) => {

            if (satir === null || satir === undefined) {
                reject("Satır seçmeliniz");
            }
            else {
                service.post(siteApi + 'websube/AktifYap/' + satir.Id, null,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + Globals.Token
                        }
                    }
                )
                    .then(res => {
                        if (res.data.Success) {
                            notify("Kaydınız aktif hale getirildi.", 'success', 5000);
                            resolve("kayıt aktif hale getirildi.");
                        }
                        else {
                            notify(res.data.ErrorMessage, 'error', 5000);
                            reject(res.data.ErrorMessage);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            }
        });
    }

    async kayitPasifYap() {
        const satir = this.satir;
        return new Promise((resolve, reject) => {

            if (satir === null || satir === undefined) {
                reject("Satır seçmelisiniz");
            }
            else {
                service.post(siteApi + 'websube/PasifYap/' + satir.Id,
                    null,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + Globals.Token
                        }
                    }
                )
                    .then(res => {
                        if (res.data.Success) {
                            notify("Kaydınız pasif hale getirildi.", 'success', 5000);
                            resolve("kayıt pasif hale getirildi");
                        }
                        else {
                            notify(res.data.ErrorMessage, 'error', 5000);
                            reject(res.data.ErrorMessage);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })
            }
        });
    }

    async kayitYenile() {
        return new Promise((resolve, reject) => {
            this.setLoadingText("şubeler getiriliyor...");
            service.get(siteApi + 'websube/getSubeler', {
                headers: {
                    'Authorization': 'Bearer ' + Globals.Token
                }
            }).then(res => {
                if (res.data.Success) {
                    this.setState({
                        subeler: res.data.Data
                    });
                    this.setLoadingText("");
                    this.satirIptal();
                    resolve("kayıtlar getirildi...");
                }
                else {
                    notify(res.data.ErrorMessage, 'error', 500);
                    this.setLoadingText("");
                    this.satirIptal();
                    reject(res.data.ErrorMessage);
                }
            }).catch(error => {
                this.setLoadingText("");
                this.satirIptal();
                reject(error);
            })
        })
    }

    async kayitSil() {
        const satir = this.satir;
        return new Promise((resolve, reject) => {

            if (satir === null || satir === undefined) {
                reject("Satır seçmelisiniz");
            }
            else {
                let result = confirm("<i>Kaydınızı silmek istiyorsunuz, emin misiniz?<br/><font color='red'>Bunun yerine pasif etmeyi seçebilirsiniz</font></i>", "Silme Onay");
                result.then((dialogResult) => {
                    if (dialogResult === true) {
                        service.post(siteApi + "websube/subeSil/" + satir.Id,
                            null, {
                            headers: {
                                'Authorization': 'Bearer ' + Globals.Token
                            }
                        })
                            .then(res => {
                                if (res.data.Success) {
                                    notify("Silme işleminiz gerçekleştirildi.", "success", 5000);
                                    resolve("Silme işleminiz gerçekleştirildi.");
                                }
                                else {
                                    notify(res.data.ErrorMessage, "error", 5000);
                                    reject(res.data.ErrorMessage);
                                }
                            })
                            .catch(error => {
                                reject(error);
                            });
                    }
                });
            }
        });

    }

    //#endregion

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-12">
                        {this.state.loadingText}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <h3>Şubeler</h3>
                        <DataGrid
                            showBorders={true}
                            showRowLines={true}
                            dataSource={this.state.subeler}
                            repaintChangesOnly={true}
                            columnHidingEnabled={true}
                            filterPanel={{ visible: true }}
                            filterRow={{ visible: true }}
                            columnAutoWidth={true}
                            selection={{ mode: 'single' }}
                            autoNavigateToFocusedRow={true}
                            focusedRowEnabled={true}
                            hoverStateEnabled={true}
                            rowAlternationEnabled={true}
                            keyExpr="Id"
                            paging={{
                                pageSize: 20
                            }}
                            pager={{
                                showPageSizeSelector: true,
                                allowedPageSizes: [5, 10, 50, 75, 100],
                                showInfo: true
                            }}
                            onSelectionChanged={this.onSelectionChanged}
                            onToolbarPreparing={this.onToolbarPreparing}
                            onInitialized={this.onInitialized}
                            ref={(ref) => this.mainDataGrid = ref}
                        >
                            <Export enabled={true} fileName={'Firmalar'} allowExportSelectedData={false} />
                            <ColumnChooser enabled={true} />
                            <Editing mode={'popup'} />
                            <Grouping contextMenuEnabled={true} expandMode="rowClick" />
                            <GroupPanel visible={true} emptyPanelText="Gruplamak için sütün başlıklarını buraya sürükleyiniz." />
                            <ColumnFixing enabled={true} />
                            <LoadPanel enabled={true} />
                            <FilterRow visible={false} applyFilter={'auto'} />
                            <Paging defaultPageSize={15} />
                            <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={false} />
                            <Column dataField={'Id'} caption="Id" visible={false} />
                            <Column dataField={'Deleted'} caption="Pasif" />
                            <Column dataField={'Kodu'} caption="Kodu" />
                            <Column dataField={'Adi'} caption="Adı" />
                            <Column dataField={'Telefon'} caption="Telefon" />
                        </DataGrid>
                        <Popup showTitle={true} shading={false} dragEnabled={true} ref={(ref) => this.popupForm = ref}
                            close_ClickOnOutsideClick={false} popupOptions={{ animation: null }} showCloseButton={false}
                            onHiding={this.popupGizle} visible={this.state.popup}
                            toolbarItems={
                                [
                                    {
                                        location: 'after', widget: 'dxButton', options: {
                                            text: 'Kaydet', type: 'success', hint: 'Kaydet', icon: 'check'
                                            , onClick: async (e) => {

                                                await this.kaydet().then(res => {
                                                    console.log(res);
                                                }).catch(error => {
                                                    alert("Kayıt esnasında hata oluştu," + error);
                                                });

                                                await this.kayitYenile().then(res => {
                                                    console.log(res);
                                                }).catch(error => {
                                                    alert("Şube bilgileri getirilirken hata oluştu," + error);
                                                });

                                                await this.setLoadingText("");

                                                await this.popupGizle();
                                            }
                                        }
                                    },
                                    {
                                        location: 'after', widget: 'dxButton', options: {
                                            text: 'Vazgeç', type: 'danger', hint: 'Çıkış', icon: 'runner'
                                            , onClick: () => { this.popupGizle(); }
                                        }
                                    }

                                ]
                            }
                        >
                            <Position my={'center'} at={'center'} of={window} />
                            <ScrollView id={'scrollview'} showScrollBarMode='onScroll' scrollByContent={true} scrollByThumb={true} bounceEnabled={false}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Form formData={this.formData}>
                                            <GroupItem cssClass="first-group" colCount={2} caption="Şube Bilgileri">
                                                <SimpleItem dataField={"Adi"}
                                                    editorOptions={{ placeholder: "Adı yazınız" }}>
                                                    <Label text="Adı" />
                                                </SimpleItem>
                                                <SimpleItem dataField={"Kodu"}
                                                    editorOptions={{ placeholder: "Kodu yazınız" }}>
                                                    <Label text="Kodu" />
                                                </SimpleItem>
                                            </GroupItem>
                                            <GroupItem cssClass="first-group" colCount={3} caption="Konum  Bilgileri">
                                                <SimpleItem dataField={"Ulke.Id"}
                                                    editorType="dxSelectBox"
                                                    editorOptions={{
                                                        dataSource: this.state.ulkeler,
                                                        searchEnabled: true,
                                                        value: this.state.secilenUlke,
                                                        displayExpr: "Adi",
                                                        valueExpr: "Id",
                                                        onValueChanged: (e) => {
                                                            this.setState({
                                                                secilenUlke: e.value
                                                            });
                                                            if (this.state.secilenUlke !== null) {
                                                                service.get(siteApi + "ortak/GetIller/" + this.state.secilenUlke,
                                                                    {
                                                                        headers: {
                                                                            'Authorization': 'Bearer ' + Globals.Token
                                                                        }
                                                                    })
                                                                    .then(res => {
                                                                        if (res.data.Success) {
                                                                            this.setState({
                                                                                iller: res.data.Data
                                                                            });
                                                                        }
                                                                        else
                                                                            notify(res.data.ErrorMessage, "error", 5000);
                                                                    });
                                                            }
                                                        }
                                                    }}>
                                                    <Label text="Ülke" />
                                                </SimpleItem>

                                                <SimpleItem dataField={"Il.Id"}
                                                    editorType="dxSelectBox"
                                                    editorOptions={{
                                                        items: this.state.iller,
                                                        searchEnabled: true,
                                                        value: this.state.secilenIl,
                                                        displayExpr: "Adi",
                                                        valueExpr: "Id",
                                                        onValueChanged: (e) => {
                                                            if (e.value === "" | e.value === null)
                                                                return;
                                                            else {
                                                                this.setState({
                                                                    secilenIl: e.value
                                                                });
                                                                if (this.state.secilenIl !== null) {
                                                                    service.get(siteApi + "ortak/GetIlceler/" + this.state.secilenIl,
                                                                        {
                                                                            headers: {
                                                                                'Authorization': 'Bearer ' + Globals.Token
                                                                            }
                                                                        })
                                                                        .then(res => {
                                                                            if (res.data.Success) {
                                                                                this.setState({
                                                                                    ilceler: res.data.Data,
                                                                                });

                                                                            }
                                                                            else
                                                                                notify(res.data.ErrorMessage, "error", 5000);
                                                                        });
                                                                }
                                                            }
                                                        }
                                                    }}>
                                                    <Label text="İl" />
                                                </SimpleItem>
                                                <SimpleItem dataField={"Ilce.Id"}
                                                    editorType="dxSelectBox"
                                                    editorOptions={{
                                                        items: this.state.ilceler,
                                                        searchEnabled: true,
                                                        value: this.state.secilenIlce,
                                                        displayExpr: "Adi",
                                                        valueExpr: "Id",
                                                        onValueChanged: (e) => {
                                                            if (e.value === "" | e.value === null)
                                                                return;
                                                            else {

                                                                this.setState({
                                                                    secilenIlce: e.value
                                                                });

                                                            }
                                                        }
                                                    }}>
                                                    <Label text="İlçe" />
                                                </SimpleItem>
                                                <SimpleItem dataField={"Adres"}>
                                                    <Label text="Adres" />
                                                </SimpleItem>
                                                <SimpleItem dataField={"Koordinat"}>
                                                    <Label text="Koordinat" />
                                                </SimpleItem>
                                            </GroupItem>
                                            <GroupItem cssClass="first-group" colCount={2} caption="İletişim Bilgileri">
                                                <SimpleItem dataField={"Telefon"}>
                                                    <Label text="Telefon" />
                                                </SimpleItem>
                                                <SimpleItem dataField={"PaketTelefonu"}>
                                                    <Label text="Paket Telefonu" />
                                                </SimpleItem>
                                                <SimpleItem dataField={"CepTelefonu"}>
                                                    <Label text="CepTelefonu" />
                                                </SimpleItem>
                                                <SimpleItem dataField={"Email"}
                                                    editorOptions={{ placeholder: "Email yazınız" }}>
                                                    <Label text="Email" />
                                                </SimpleItem>
                                                <SimpleItem dataField={"YetkiliBir"}
                                                    editorOptions={{ placeholder: "Yetkili Ad Soyad" }}>
                                                    <Label text="Yetkili 1" />
                                                </SimpleItem>
                                                <SimpleItem dataField={"YetkiliIki"}
                                                    editorOptions={{ placeholder: "Yetkili Ad Soyad" }}>
                                                    <Label text="Yetkili 2" />
                                                </SimpleItem>
                                            </GroupItem>
                                        </Form>
                                    </div>
                                </div>
                            </ScrollView>
                        </Popup>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}
export default withRouter(SubelerPage);
